import feathersClient from '../feathersClient';
import { ResourceShow } from '../models/classrooms';

export default () => {
  return feathersClient
    .service('users')
    .get('me')
    .then(user => {
      let service = '';
      switch (user.entiny_type) {
        case 'School':
          service = 'schools';
          break;
        case 'Teacher':
          service = 'teachers';
          break;
        case 'Contributor':
          service = 'contributors';
          break;
        case 'Franchisee':
          service = 'franchisees';
          break;
        default:
          break;
      }
      return feathersClient
        .service(service)
        .get(user.entiny_id)
        .then(resource => {
          let data;
          if (service == 'schools' || service == 'franchisees') {
            data = {
              entiny_type: user.entiny_type,
              _id: resource._id,
              email: user.email,
              company: resource.company,
              company_name: resource.company_name,
              cnpj: resource.cnpj
            };
          }
          if (service == 'teachers') {
            data = {
              entiny_type: user.entiny_type,
              _id: resource._id,
              email: user.email,
              name: resource.name
            };
          }
          if (service == 'contributors') {
            data = {
              entiny_type: user.entiny_type,
              _id: resource._id,
              email: user.email,
              name: resource.name
            };
          }

          return data;
        });
    });
};
