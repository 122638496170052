import feathers from '../feathersClient';
const emailValidation = async (email, allValues, props) => {
  const initialEmail = props.initial;
  if (email !== initialEmail) {
    return await feathers
      .service('users')
      .find({ query: { email: email } })
      .then((response) => {
        if (response.total > 0) {
          var err = { email: 'Email já cadastrado' };
          return 'Email já cadastrado';
        }
      });
  }
};
export default emailValidation;
