// 'Contributor' 'Franchisee' 'School' 'Teacher'
const permissions = {
  franchisees: {
    list: ['Contributor'],
    create: ['Contributor'],
    edit: ['Contributor'],
  },
  schools: {
    list: ['Contributor', 'Franchisee'],
    create: ['Contributor'],
    edit: ['Contributor', 'Franchisee'],
  },
  activities: {
    edit: ['Contributor', 'School', 'Franchisee'],
    onlyRead: ['Franchisee'],
  },
  // Default nos Actions abaixo é usado pois o componente realiza apenas uma verificção geral
  teachers: {
    default: ['Contributor', 'Franchisee', 'School'],
  },
  programs: {
    default: ['Contributor'],
  },
  materials: {
    list: ['Contributor'],
    create: ['Contributor'],
    edit: ['Contributor'],
  },
  whthemes: {
    list: ['Contributor'],
    create: ['Contributor'],
    edit: ['Contributor'],
  },
  whregisters: {
    list: ['Contributor'],
    create: ['Contributor'],
    edit: ['Contributor'],
  },
  gallery: {
    default: ['Contributor', 'Franchisee', 'School'],
  },
  hoTips: {
    list: ['Contributor'],
    create: ['Contributor'],
    edit: ['Contributor'],
  },
};
const canDo = (permission, resource, action) => {
  return permissions[resource][action].includes(permission);
};
export default canDo;
