import React from 'react';
import {
  BooleanInput,
  Edit,
  email,
  required,
  SimpleForm,
  TextInput
} from 'react-admin';
import GeneratePasswordButton from '../../../components/GeneratePasswordButton';
import emailValidation from '../../../validations/emailValidation';

const Title = ({ record }) => {
  return <span>{record ? `${record.company_name}` : ''}</span>;
};

const ResourceEdit = props => {
  return (
    <Edit
      bulkActionButtons={false}
      {...props}
      title={<Title />}
      // filters={<ResourceFilter />}
    >
      <SimpleForm>
        <GeneratePasswordButton />
        <BooleanInput source="own_base" label="Base própria" />
        <TextInput
          fullWidth
          source="email"
          label="Email"
          validate={[required(), email(), emailValidation]}
        />
        <TextInput
          fullWidth
          source="company"
          label="Razão Social"
          validate={required()}
        />
        <TextInput
          fullWidth
          source="company_name"
          label="Nome Fantasia"
          validate={required()}
        />
      </SimpleForm>
    </Edit>
  );
};
export default ResourceEdit;
