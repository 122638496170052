import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import feathers from '../../feathersClient';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Loader from 'react-loader-spinner';

const useStyles = makeStyles({
  root: {
    margin: 10,
    maxWidth: 345,
    width: '100%'
  },
  media: {
    height: 140
  },
  inputFilters: {
    minWidth: 200,
    width: 'auto',
    margin: 10
  }
});

const ImageList = ({ activities, filtersOn = true }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [filter, setFilter] = useState({
    material: null,
    teacher: null,
    program: null,
    minDate: null,
    maxDate: null,
    school: null,
    classroom: null
  });
  const [filterMaterials, setFilterMaterials] = useState([]);
  const [filterTeachers, setFilterTeachers] = useState([]);
  const [filterPrograms, setFilterPrograms] = useState([]);
  const [filterMinDate, setFilterMinDate] = useState(null);
  const [filterMaxDate, setFilterMaxDate] = useState(null);
  const [filterSchools, setFilterSchools] = useState([]);
  const [filterClassrooms, setFilterClassrooms] = useState([]);

  useEffect(() => {
    getResources();
  }, [activities]);

  useEffect(() => {
    let queryFilter = {};
    let _filteredActivities = activities;
    Object.keys(filter).map(key => {
      console.log('key', key);
      if (filter[key] !== null) {
        queryFilter[key] = filter[key];
      }
    });
    console.log('queryFilter', queryFilter);
    let final = _filteredActivities.filter(activity => {
      for (var key in queryFilter) {
        if (key == 'minDate' || key == 'maxDate') {
          if (key == 'minDate') {
            if (
              new Date(activity['buildedAt']).getTime() <
              new Date(queryFilter[key]).getTime()
            )
              return false;
          }
          if (key == 'maxDate') {
            if (
              new Date(activity['buildedAt']).getTime() >
              new Date(queryFilter[key]).getTime()
            )
              return false;
          }
        } else {
          if (activity[key] === undefined || activity[key] != queryFilter[key])
            return false;
        }
      }
      return true;
    });
    console.log('final', final);
    setFilteredActivities(final);
  }, [filter]);

  const download = url => {
    setDownloading(true);
    axios({
      method: 'get',
      url: 'http://localhost:3020/images',
      params: {
        urls: [decodeURI(url)]
      }
    }).then(response => {
      console.log('response', response);
      const _url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = _url;
      link.setAttribute('download', 'imagens.zip');
      document.body.appendChild(link);
      link.click();
      setDownloading(false);
    });
  };

  const downloadAll = async () => {
    setDownloading(true);
    let urls = [];

    Promise.all(
      filteredActivities.map(filteredActivity => {
        return new Promise((resolve, reject) => {
          filteredActivity.images.map(image => {
            urls.push(image.url);
          });
          resolve();
        });
      })
    ).then(() => {
      axios({
        method: 'get',
        url: 'http://localhost:3020/images',
        params: {
          urls: urls
        },
        responseType: 'blob'
      }).then(response => {
        console.log('response', response);
        const _url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = _url;
        link.setAttribute('download', 'imagens.zip');
        document.body.appendChild(link);
        link.click();
        setDownloading(false);
      });
    });
  };

  //   const download = url => {
  //     console.log('url', url);
  //     var element = document.createElement('a');
  //     var file = new Blob(
  //       [
  //         'https://timesofindia.indiatimes.com/thumb/msid-70238371,imgsize-89579,width-400,resizemode-4/70238371.jpg'
  //       ],
  //       { type: 'image/*' }
  //     );
  //     element.href = URL.createObjectURL(file);
  //     element.download = 'Foto.png';
  //     element.click();
  //   };

  const getResources = async () => {
    setFilteredActivities(activities);
    let _teachers = await feathers
      .service('teachers')
      .find({ query: { $limit: '-1' } });
    let _materials = await feathers
      .service('materials')
      .find({ query: { $limit: '-1' } });
    let _classrooms = await feathers
      .service('classrooms')
      .find({ query: { $limit: '-1' } });
    let _programs = await feathers
      .service('programs')
      .find({ query: { $limit: '-1' } });
    setTeachers(_teachers);
    setMaterials(_materials);
    setClassrooms(_classrooms);
    setPrograms(_programs);
    if (filtersOn == true) getFilters();

    setLoading(false);
  };

  const getFilters = async () => {
    let _filterMaterials = [];
    let _filterTeachers = [];
    let _filterPrograms = [];
    let _filterSchools = [];
    let _filterClassrooms = [];
    let _filterActivityDates = [];
    let _filterMinDate = new Date();
    let _filterMaxDate = new Date('2000-01-01');
    Promise.all(
      activities.map(activity => {
        if (activity.images.length > 0) {
          return new Promise((resolve, reject) => {
            if (!_filterMaterials.includes(activity.material)) {
              _filterMaterials.push(activity.material);
            }
            if (!_filterTeachers.includes(activity.teacher)) {
              _filterTeachers.push(activity.teacher);
            }
            if (!_filterPrograms.includes(activity.program)) {
              _filterPrograms.push(activity.program);
            }
            if (!_filterSchools.includes(activity.school)) {
              _filterSchools.push(activity.school);
            }
            if (!_filterClassrooms.includes(activity.classroom)) {
              _filterClassrooms.push(activity.classroom);
            }

            // if (!_filterActivityDates.includes(activity.buildedAt)) {
            // _filterActivityDates.push(new Date(activity.buildedAt).getTime());
            // }

            resolve();
          });
        }
      })
    ).then(() => {
      setFilterMaterials(_filterMaterials);
      setFilterTeachers(_filterTeachers);
      setFilterPrograms(_filterPrograms);
      setFilterSchools(_filterSchools);
      setFilterClassrooms(_filterClassrooms);
    });
  };

  return (
    <div style={{ margin: 10 }}>
      {downloading == true && (
        <>
          <div
            style={{
              position: 'fixed',
              width: '100vw',
              height: '100vh',
              backgroundColor: '#000',
              opacity: 0.7,
              zIndex: 9999,
              top: 0,
              left: 0
            }}
          ></div>
          <Loader
            style={{
              position: 'fixed',
              top: '45%',
              left: '45%',
              zIndex: 99999
            }}
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
          />
        </>
      )}
      {Object.keys(filter).length > 0 && filtersOn == true && (
        <div
          style={{
            display: 'flex',
            flex: 1,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
          <FormControl className={classes.inputFilters}>
            <InputLabel>Atividade</InputLabel>
            <Select
              value={filter.material}
              onChange={e => {
                setFilter({ ...filter, material: e.target.value });
              }}
            >
              <MenuItem value={null}>Todas as atividades</MenuItem>
              {materials
                .filter(_material => filterMaterials.includes(_material._id))
                .map(material => (
                  <MenuItem value={material._id}>{material.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl className={classes.inputFilters}>
            <InputLabel>Professor</InputLabel>
            <Select
              value={filter.teacher}
              onChange={e => {
                setFilter({ ...filter, teacher: e.target.value });
              }}
            >
              <MenuItem value={null}>Todos os professores</MenuItem>
              {teachers
                .filter(_teacher => filterTeachers.includes(_teacher._id))
                .map(teacher => (
                  <MenuItem value={teacher._id}>{teacher.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl className={classes.inputFilters}>
            <InputLabel>Programa</InputLabel>
            <Select
              value={filter.program}
              onChange={e => {
                setFilter({ ...filter, program: e.target.value });
              }}
            >
              <MenuItem value={null}>Todos os Programas</MenuItem>
              {programs
                .filter(_program => filterPrograms.includes(_program._id))
                .map(program => (
                  <MenuItem value={program._id}>{program.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl className={classes.inputFilters}>
            <InputLabel>Turma</InputLabel>
            <Select
              value={filter.classroom}
              onChange={e => {
                setFilter({ ...filter, classroom: e.target.value });
              }}
            >
              <MenuItem value={null}>Todas as turmas</MenuItem>
              {classrooms
                .filter(_classroom => filterClassrooms.includes(_classroom._id))
                .map(classroom => (
                  <MenuItem value={classroom._id}>{classroom.name}</MenuItem>
                ))}
            </Select>
          </FormControl>

          <TextField
            className={classes.inputFilters}
            label="De"
            type="date"
            // defaultValue={filterMinDate.toISOString().substr(0, 10)}
            // defaultValue={`2000-01-01`}
            // className={classes.textField}
            onChange={e => {
              if (e.target.value.length == 0) {
                setFilter({ ...filter, minDate: null });
              } else {
                setFilter({ ...filter, minDate: e.target.value });
              }
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            className={classes.inputFilters}
            label="Até"
            type="date"
            onChange={e => {
              if (e.target.value.length == 0) {
                setFilter({ ...filter, maxDate: null });
              } else {
                setFilter({ ...filter, maxDate: e.target.value });
              }
            }}
            // defaultValue={filterMinDate.toISOString().substr(0, 10)}
            // defaultValue={`2000-01-01`}
            // className={classes.textField}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
      )}
      {loading == false &&
        (filteredActivities.length == 0 ||
          teachers.length == 0 ||
          materials.length == 0 ||
          classrooms.length == 0 ||
          programs.length == 0) && (
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              margin: 40
            }}
          >
            <Typography gutterBottom variant="h5" component="h2">
              Sem atividades
            </Typography>
          </div>
        )}
      {loading == false && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'flex-end'
          }}
        >
          <Button
            style={{ marginRight: 40, marginBottom: 20, marginTop: 20 }}
            onClick={() => {
              downloadAll();
            }}
            // style={{ marginLeft: 'auto' }}
            color="primary"
          >
            SALVAR TODAS AS IMAGENS
          </Button>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center'
        }}
      >
        {loading == false &&
          filteredActivities.map(activity => {
            let material = materials.find(
              _material => _material._id == activity.material
            );
            let teacher = teachers.find(
              _teacher => _teacher._id == activity.teacher
            );
            let classroom = classrooms.find(
              _classroom => _classroom._id == activity.classroom
            );
            return activity.images.map(image => (
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={image.url}
                    title="ZOOM"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {material.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Feita em:{' '}
                      {(activity.buildedAt instanceof Date
                        ? activity.buildedAt.toLocaleString('en-GB')
                        : new Date(activity.buildedAt).toLocaleString('en-GB')
                      ).substring(0, 17)}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Professor: {teacher.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Turma: {classroom.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <FacebookShareButton
                    url={image.url}
                    quote={`${material.name} Zoom Education`}
                    hashtag="#zoomeducation"
                  >
                    <FacebookIcon />
                  </FacebookShareButton>
                  <Button
                    onClick={() => {
                      download(image.url);
                    }}
                    style={{ marginLeft: 'auto' }}
                    color="primary"
                  >
                    SALVAR
                  </Button>
                </CardActions>
              </Card>
            ));
          })}
      </div>
    </div>
  );
};

export default ImageList;
