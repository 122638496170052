import React from 'react';
import PropTypes from 'prop-types';

const DateField = ({ source, record = {} }) => (
  <span>
    {record[source] instanceof Date
      ? record[source].toLocaleString('en-GB')
      : new Date(record[source]).toLocaleString('en-GB')}
  </span>
);

DateField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired
};

export default DateField;
