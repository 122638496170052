import React from 'react';
import { Create, email, required, SimpleForm, TextInput } from 'react-admin';
import emailValidation from '../../../validations/emailValidation';

const ResourceCreate = props => (
  <Create {...props} title="Novo Professor">
    <SimpleForm>
      <TextInput
        fullWidth
        source="email"
        label="Email"
        validate={[required(), email(), emailValidation]}
      />
      <TextInput fullWidth source="name" label="Nome" validate={required()} />
      <TextInput fullWidth source="cpf" label="Cpf" validate={required()} />
      <TextInput fullWidth source="tel" label="Tel" validate={required()} />
    </SimpleForm>
  </Create>
);
export default ResourceCreate;
