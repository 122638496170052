import React from 'react';
import { Datagrid, EditButton, List, TextField } from 'react-admin';

import ResourceActions from './Actions';
import SchoolFilter from './Filter';
import exporter from '../funcs/exporter';

const SchoolList = ({ permissions, ...props }) => (
  <List
    bulkActionButtons={false}
    {...props}
    title={'Escolas'}
    filters={<SchoolFilter />}
    actions={<ResourceActions permissions={permissions} />}
    exporter={exporter}
  >
    <Datagrid>
      {/* <TextField source="id" label="#" /> */}
      <TextField source='company' label='Razão social' />
      <TextField source='company_name' label='Nome fantasia' />
      <TextField source='cnpj' />
      <TextField source='email' />
      <EditButton />
    </Datagrid>
  </List>
);

export default SchoolList;
