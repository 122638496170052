import feathers from '../../../feathersClient';
import XLSX from 'xlsx';

const exporter = resources => {
  let data = [['Nome']];
  Promise.all(
    resources.map(resource => {
      return new Promise(async (resolve, reject) => {
        data.push([resource.name]);
        resolve();
      });
    })
  ).then(() => {
    let worksheet = XLSX.utils.aoa_to_sheet(data);
    let new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      new_workbook,
      worksheet,
      'Tema de atividade em casa'
    );
    XLSX.writeFile(new_workbook, `Tema_de_atividade_em_casa.xlsx`);
  });
};

export default exporter;
