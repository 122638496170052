import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
} from 'react-admin';
import DateField from '../../../components/DateField/index';

import exporter from '../funcs/exporter';

import ResourceFilter from './Filters';

import ResourceActions from './Actions';

const ResourceList = ({ permissions, ...props }) => {
  return (
    <List
      bulkActionButtons={false}
      {...props}
      title={'Atividades'}
      actions={<ResourceActions />}
      filters={<ResourceFilter permissions={permissions} />}
      exporter={exporter}
    >
      <Datagrid>
        <ReferenceField
          label='Atividade'
          source='material'
          reference='materials'
          link={false}
        >
          <TextField source='name' />
        </ReferenceField>
        <ReferenceField
          label='Programa'
          source='program'
          reference='programs'
          link={false}
        >
          <TextField source='name' />
        </ReferenceField>
        {(permissions === 'Contributor' || permissions === 'School') && (
          <ReferenceField
            label='Professor'
            source='teacher'
            reference='teachers'
            link={false}
          >
            <TextField source='name' />
          </ReferenceField>
        )}
        <ReferenceField label='Turma' source='classroom' reference='classrooms'>
          <TextField source='name' />
        </ReferenceField>
        <DateField
          locales='en-GB'
          label='Feita em'
          source='buildedAt'
          showTime
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default ResourceList;
