import React from 'react';
import {
  DateTimeInput,
  Edit,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

const ResourceEdit = ({ permissions, ...props }) => (
  <Edit title='Cronograma' {...props}>
    <SimpleForm>
      {(permissions == 'Contributor' || permissions == 'School') && (
        <ReferenceInput
          fullWidth
          label='Professor'
          source='teacher'
          reference='teachers'
          validate={required()}
        >
          <SelectInput
            validate={required()}
            allowEmpty={false}
            fullWidth
            optionText='name'
          />
        </ReferenceInput>
      )}
      <ReferenceInput
        fullWidth
        label='Atividade'
        source='material'
        reference='materials'
        validate={required()}
      >
        <SelectInput
          validate={required()}
          allowEmpty={false}
          fullWidth
          optionText='name'
        />
      </ReferenceInput>
      <ReferenceInput
        fullWidth
        label='Turma'
        source='classroom'
        reference='classrooms'
        validate={required()}
      >
        <SelectInput
          validate={required()}
          allowEmpty={false}
          fullWidth
          optionText='name'
        />
      </ReferenceInput>
      <DateTimeInput
        fullWidth
        source='scheduleAt'
        label='Data'
        validate={required()}
        options={{
          format: 'DD/MM/YYYY, HH:mm:ss',
          ampm: false,
          clearable: true,
        }}
      />
      <TextInput
        validate={required()}
        fullWidth
        source='obs'
        label='Observação'
      />
    </SimpleForm>
  </Edit>
);

export default ResourceEdit;
