import React from 'react';

import { TextInput, Create, SimpleForm } from 'react-admin';

export const ResourceCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" label="Nome" />
    </SimpleForm>
  </Create>
);

export default ResourceCreate;
