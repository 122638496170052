import React from 'react';
import 'video-react/dist/video-react.css';

const VideoPlayer = props => {
  if (!props.record.youtubeVideo) {
    return null;
  }

  let _player;
  let videoId = youtube_parser(props.record.youtubeVideo);

  return (
    <iframe
      title='video'
      width='560'
      height='315'
      src={'https://www.youtube.com/embed/' + videoId}
      allow='accelerometer; autoplay; encrypted-media'
      allowfullscreen='allowfullscreen'
    ></iframe>
  );
};

// Função usada para checar o ID do video, já que o link de youtube possui diversos formatos

function youtube_parser(url) {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
}

export default VideoPlayer;
