import React from 'react';
import { Filter, TextInput, SelectInput } from 'react-admin';

const ReseourceFilter = (props) => (
  <Filter {...props}>
    <TextInput fullWidth alwaysOn source='title[$search]' label='Título' />
    <TextInput
      fullWidth
      alwaysOn
      source='description[$search]'
      label='Descrição'
    />
    {/* <TextInput
      fullWidth
      source='youtubeVideo'
      label='Video (Link de YouTube)'
    /> */}
    <SelectInput
      fullWidth
      alwaysOn
      source='userType'
      label='Tipo de usuário'
      choices={[
        { id: 'teacher', name: 'Professor' },
        { id: 'family', name: 'Família' },
      ]}
    />
  </Filter>
);

export default ReseourceFilter;
