import React from 'react';
import {
  Edit,
  TextInput,
  SimpleForm,
  required,
  Toolbar,
  SaveButton,
  minLength
} from 'react-admin';

import FormToolbar from '../components/FormToolbar';

const SelectEdit = props => {
  console.log('props123', props);

  return (
    <SimpleForm {...props} toolbar={<FormToolbar />}>
      <TextInput
        fullWidth
        type='password'
        source='oldPassword'
        label='Senha antiga'
        initiallyVisible
        validate={(required(), minLength(6))}
      />
      <TextInput
        fullWidth
        type='password'
        source='newPassword'
        label='Nova senha'
        initiallyVisible
        validate={[required(), minLength(6)]}
      />
      {/* <TextInput fullWidth source="confirmPassword" label="Confirmar nova senha" validate={required()} /> */}
    </SimpleForm>
  );
};

const ProfileEdit = ({ staticContext, ...props }) => {
  return (
    <Edit
      id='my-profile'
      resource='changepassword'
      basePath='/change-password'
      redirect={false}
      title='Alterar senha'
      {...props}
    >
      <SelectEdit />
    </Edit>
  );
};

export default {
  edit: ProfileEdit
};
