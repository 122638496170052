import React from 'react';
import {
  Datagrid,
  EditButton,
  List,
  RichTextField,
  TextField,
  DateField,
  SingleFieldList,
} from 'react-admin';
import ResourceActions from './Actions';
import ResourceFilter from './Filters';
import MyTypeArrayField from './MyTypeArrayField';

const ResourceList = ({ permissions, ...props }) => {
  return (
    <List
      bulkActionButtons={false}
      {...props}
      title={'Dicas Home Office'}
      actions={<ResourceActions />}
      filters={<ResourceFilter permissions={permissions} />}
    >
      <Datagrid>
        <TextField source='title' label='Título' />
        <DateField source='availableAt' label='Disponivel em' />
        <RichTextField source='description' label='Descrição' />
        <MyUrlField {...props} label='Video' />
        <MyTypeArrayField source='userType' label='Tpos de usuário'>
          <SingleFieldList>
            <MyTypeField source='id' />
          </SingleFieldList>
        </MyTypeArrayField>
        <EditButton />
      </Datagrid>
    </List>
  );
};

const MyTypeField = ({ record, source }) => {
  let type;
  switch (record.userType) {
    case 'family':
      type = 'Família';
      break;
    case 'teacher':
      type = 'Professor';
      break;
    default:
      return null;
  }
  return <p>{type}</p>;
};

const MyUrlField = ({ record, source }) => {
  return record.youtubeVideo ? (
    <a href={record.youtubeVideo} target='_blank'>
      Clique para assistir
    </a>
  ) : null;
};

export default ResourceList;
