import React from 'react';
import {
  BooleanInput,
  DateInput,
  Edit,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import VideoPlayer from './VideoPlayer';

const dateParser = (v) => {
  let time = v;
  time += 'T04:00';
  if (v.charAt(0) != '0') {
    return time;
  }
  return v;
};

const Title = ({ record }) => {
  console.log(record);
  return <span>{record ? `${record.title}` : ''}</span>;
};

export const ResourceEdit = ({ permissions, ...props }) => {
  console.log(props);
  return (
    <>
      <Edit {...props} title={<Title />}>
        <SimpleForm>
          <BooleanInput source='status' label='Status' />
          <TextInput
            fullWidth
            source='title'
            label='Título'
            validate={[required()]}
          />
          <DateInput
            fullWidth
            source='availableAt'
            label='Disponivel em'
            options={{
              format: 'DD/MM/YYYY, HH:mm:ss',
              ampm: false,
              clearable: false,
            }}
            parse={dateParser}
            validate={[required()]}
          />
          <RichTextInput
            fullWidth
            source='description'
            label='Descrição'
            validate={[required()]}
          />
          <TextInput
            fullWidth
            source='youtubeVideo'
            label='Video (Link de YouTube)'
            validate={[required()]}
          />

          <VideoPlayer {...props} />
          <SelectArrayInput
            fullWidth
            source='userType'
            label='Tipo de usuário'
            choices={[
              { id: 'teacher', name: 'Professor' },
              { id: 'family', name: 'Família' },
            ]}
            validate={[required()]}
          />
        </SimpleForm>
      </Edit>
    </>
  );
};

export default ResourceEdit;
