import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { FileInput, FileField, FormDataConsumer } from 'react-admin';
import axios from 'axios';
import feathers from '../../feathersClient';

import { useForm } from 'react-final-form';

const MaterialImageUpload = () => {
  const [upload, setUpload] = useState(false);
  const form = useForm();
  const uploadFile = async (formData, data) => {
    setUpload(true);
    const file = data[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      console.log(event.target.result);
      feathers
        .service('material-thumbs')
        .create({ image: event.target.result })
        .then((result) => {
          console.log('result', result);
          formData['thumb'] = result.image;
          setUpload(false);
          form.change('thumb', result.image);
          form.reset();
        });
    };
    reader.readAsDataURL(file);
  };

  return (
    <FormDataConsumer>
      {({
        formData, // The whole form data
        getSource, // A function to get the valid source inside an ArrayInput
        ...rest
      }) => {
        return (
          <>
            {upload == true && (
              <div
                style={{
                  top: 0,
                  left: 0,
                  position: 'absolute',
                  zIndex: 999,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    color: '#999',
                  }}
                >
                  <img src='/loading.gif' />
                </div>
              </div>
            )}
            <div
              style={{
                margin: '0 auto',
                width: 200,
                textAlign: 'center',
                display:
                  formData['thumb'] !== undefined &&
                  formData['thumb'].length > 1
                    ? 'block'
                    : 'none',
              }}
            >
              <img
                src={formData['thumb']}
                style={{
                  width: 200,
                  height: 200,
                  margin: '0 auto',
                }}
              />
            </div>
            <Dropzone
              accept='image/*'
              maxFiles={1}
              onDrop={(acceptedFiles) => {
                uploadFile(formData, acceptedFiles);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p
                      style={{
                        padding: 40,
                        textAlign: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      }}
                    >
                      Arraste ou clique para fazer upload do thumb
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
          </>
        );
      }}
    </FormDataConsumer>
  );
};

export default MaterialImageUpload;
