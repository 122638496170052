import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { FileInput, FileField, FormDataConsumer } from 'react-admin';
import axios from 'axios';
import feathers from '../../feathersClient';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';

import { useForm } from 'react-final-form';

let _player;
let _formData;
// let video = 'http://drogaraia-videos.s3.amazonaws.com/notable-videos/1.mp4';

const MaterialVideoUpload = () => {
  const [upload, setUpload] = useState(false);
  const form = useForm();

  const uploadFile = async (formData, data) => {
    setUpload(true);
    console.log('data', data);
    const file = data[0];
    const reader = new FileReader();
    reader.onload = async (event) => {
      console.log(event.target.result);
      const token = await localStorage.getItem('feathers-jwt');
      console.log('token', token);
      const base64 = event.target.result.split('base64,')[1];
      feathers
        .service('material-videos')
        .create({ video: base64 })
        .then((result) => {
          formData['zvideo'] = result.video;
          setUpload(false);
          form.change('zvideo', result.video);
          form.reset();
        });
      // axios
      //   .post(
      //     'http://localhost:3030/material-videos',
      //     { video: base64 },
      //     {
      //       headers: {
      //         Authorization: `Bearer ${token}`,
      //         'Access-Control-Allow-Origin': '*',
      //         'Access-Control-Allow-Headers':
      //           'Origin, X-Requested-With, Content-Type, Accept'
      //       }
      //     }
      //   )
      //   .then(response => {
      //     const data = response.data;
      //     formData['zvideo'] = data.video;
      //     setUpload(false);
      //     console.log(data);
      //   });
    };
    reader.readAsDataURL(file);
  };

  return (
    <FormDataConsumer>
      {({
        formData, // The whole form data
        getSource, // A function to get the valid source inside an ArrayInput
        ...rest
      }) => {
        // setVideo(formData['zvideo'].toString());
        return (
          <>
            {upload == true && (
              <div
                style={{
                  top: 0,
                  left: 0,
                  position: 'absolute',
                  zIndex: 999,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    color: '#999',
                  }}
                >
                  <img src='/loading.gif' />
                </div>
              </div>
            )}
            {formData['zvideo'] !== undefined && formData['zvideo'].length > 0 && (
              <div
                style={{
                  margin: '0 auto',
                  width: 500,
                  display: formData['zvideo'].length < 1 ? 'none' : 'block',
                }}
              >
                <Player
                  ref={(player) => {
                    _player = player;
                  }}
                  playsInline
                  poster='/assets/poster.png'
                  src={formData['zvideo']}
                />
              </div>
            )}
            <Dropzone
              accept='video/*'
              onDrop={(acceptedFiles) => {
                uploadFile(formData, acceptedFiles);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p
                      style={{
                        padding: 40,
                        textAlign: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      }}
                    >
                      Arraste ou clique para fazer upload do video
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
          </>
        );
      }}
    </FormDataConsumer>
  );
};

export default MaterialVideoUpload;
