import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
} from 'react-admin';
import DateField from '../../../components/DateField/index';

import exporter from '../funcs/exporter';

import ResourceFilter from './Filters';

import ResourceActions from './Actions';

const MyTypeField = ({ record, source }) => {
  let type;
  switch (record.userType) {
    case 'family':
      type = 'Família';
      break;
    case 'teacher':
      type = 'Professor';
      break;
    default:
      return null;
  }
  return <p>{type}</p>;
};

const ResourceList = ({ permissions, ...props }) => {
  return (
    <List
      bulkActionButtons={false}
      {...props}
      title={'Atividades'}
      actions={<ResourceActions />}
      filters={<ResourceFilter permissions={permissions} />}
      exporter={exporter}
    >
      <Datagrid>
        <TextField source={'name'} />
        <TextField source={'email'} />
        {/* <ReferenceField source={'schools'} reference={'school'}>
          <TextField source={'name'} />
        </ReferenceField> */}
        <TextField source={'school'} label={'Escola'} />
        <MyTypeField source={'userType'} label={'Tipo de usuário'} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default ResourceList;
