import React from 'react';
import { Filter, TextInput } from 'react-admin';

const ReseourceFilter = props => (
  <Filter {...props}>
    <TextInput source="name" label="Nome" alwaysOn />
  </Filter>
);

export default ReseourceFilter;
