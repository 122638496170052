import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TableCell,
  TableRow
} from '@material-ui/core';

import feathers from '../../../feathersClient';
import {
  Datagrid,
  TextField,
  Edit,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  FormTab,
  ReferenceArrayField,
  DatagridBody,
  DateTimeInput,
  required
} from 'react-admin';

import XLSX from 'xlsx';

import ImageList from '../../../components/ImageList';

import canDo from '../../../canDo';

export const ResourceEdit = ({ permissions, ...props }) => {
  const [openedDialog, setOpenedDialog] = useState(false);
  const [toRemoveStudent, setToRemoveStudent] = useState(null);
  const [loading, setLoading] = useState(false);

  const MaterialImageList = ({ record }) => {
    return <ImageList activities={[record]} filtersOn={false} />;
  };

  const listXlsx = items => {
    let students = [['Nome', 'Código']];
    Promise.all(
      items.map(student => {
        return new Promise((resolve, reject) => {
          feathers
            .service('students')
            .get(student)
            .then(student => {
              students.push([student.name, student.code]);
              resolve();
            })
            .catch(err => {});
        });
      })
    ).then(() => {
      let worksheet = XLSX.utils.aoa_to_sheet(students);
      let new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, 'Alunos');
      XLSX.writeFile(new_workbook, `Listagem de chamada.xlsx`);
    });
  };
  const ListXlsxButton = _props => {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          alignItems: 'flex-end'
        }}
      >
        <Button
          color='primary'
          variant='outlined'
          onClick={() => {
            listXlsx(_props.record.students);
          }}
        >
          Exportar
        </Button>
      </div>
    );
  };

  const MyDatagridRow = ({ record, resource, id, children, basePath }) => (
    <TableRow key={id}>
      {React.Children.map(children, field => (
        <TableCell key={`${id}-${field.props.source}`}>
          {React.cloneElement(field, {
            record,
            basePath,
            resource
          })}
        </TableCell>
      ))}
      <TableCell style={{ textAlign: 'right' }}>
        `
        {canDo(permissions, 'activities', 'onlyRead') ? null : (
          <Button
            color='primary'
            variant='outlined'
            onClick={() => {
              console.log('id', id);
              setToRemoveStudent(id);
              setOpenedDialog(true);
            }}
          >
            Remover
          </Button>
        )}
        `
      </TableCell>
    </TableRow>
  );

  const MyDatagridBody = props => (
    <DatagridBody {...props} row={<MyDatagridRow />} />
  );

  const MyDatagrid = props => <Datagrid {...props} body={<MyDatagridBody />} />;

  const closeDialog = () => {
    setToRemoveStudent(null);
    setOpenedDialog(false);
  };

  const removeStudent = () => {
    setLoading(true);
    feathers
      .service('jornadaz/activities')
      .get(props.id)
      .then(activity => {
        console.log('activity', activity);
        let students = activity.students.filter(
          student => student != toRemoveStudent
        );
        feathers
          .service('jornadaz/activities')
          .patch(props.id, { students: students })
          .then(activity => {
            console.log('activity', activity);
            window.location.reload();
          })
          .catch(err => {
            setLoading(false);
          });
      })
      .catch(err => {
        setLoading(false);
      });
  };

  return (
    <>
      <Edit title={'Atividade'} {...props}>
        <TabbedForm>
          <FormTab label='Dados'>
            <ReferenceInput
              fullWidth
              label='Atividade'
              source='material'
              reference='materials'
              validate={required()}
              disabled={canDo(permissions, 'activities', 'onlyRead')}
            >
              <SelectInput
                validate={required()}
                fullWidth
                optionText='name'
                disabled={canDo(permissions, 'activities', 'onlyRead')}
              />
            </ReferenceInput>
            {(permissions == 'School' || permissions == 'Contributor') && (
              <ReferenceInput
                fullWidth
                label='Professor'
                source='teacher'
                reference='teachers'
              >
                <SelectInput
                  fullWidth
                  optionText='name'
                  validate={required()}
                />
              </ReferenceInput>
            )}
            <ReferenceInput
              fullWidth
              label='Turma'
              source='classroom'
              reference='classrooms'
              validate={required()}
              disabled={canDo(permissions, 'activities', 'onlyRead')}
            >
              <SelectInput
                validate={required()}
                fullWidth
                optionText='name'
                disabled={canDo(permissions, 'activities', 'onlyRead')}
              />
            </ReferenceInput>
            <ReferenceInput
              fullWidth
              label='Programa'
              source='program'
              reference='programs'
              validate={required()}
              disabled={permissions == 'Contributor' ? false : true}
            >
              <SelectInput validate={required()} fullWidth optionText='name' />
            </ReferenceInput>
            <DateTimeInput
              fullWidth
              validate={required()}
              label='Feita em'
              source='buildedAt'
              disabled={
                permissions == 'Teacher'
                  ? true
                  : canDo(permissions, 'activities', 'onlyRead')
              }
            />
          </FormTab>

          <FormTab label='Lista de chamada'>
            <ListXlsxButton {...props} />
            <ReferenceArrayField
              label=''
              reference='students'
              source='students'
              fullWidth
            >
              <MyDatagrid fullWidth>
                <TextField label='Nome' source='name' fullWidth />
                <TextField label='Código' source='code' fullWidth />
              </MyDatagrid>
            </ReferenceArrayField>
          </FormTab>
          <FormTab label='Galeria de fotos'>
            <MaterialImageList />
          </FormTab>
        </TabbedForm>
      </Edit>
      <Dialog open={openedDialog}>
        <DialogTitle>"Deseja remover o aluno da atividade?"</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseja remover o aluno da atividade ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              closeDialog();
            }}
            color='primary'
          >
            Não
          </Button>
          <Button
            onClick={() => {
              removeStudent();
            }}
            color='primary'
            autoFocus
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ResourceEdit;
