import React from 'react';
import { Filter, ReferenceInput, SelectInput } from 'react-admin';

const ResourceFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      fullWidth
      label="Atividade"
      source="material"
      reference="materials"
      alwaysOn
    >
      <SelectInput fullWidth optionText="name" />
    </ReferenceInput>

    {(props.permissions == 'School' ||
      props.permissions == 'Contributor' ||
      props.permissions == 'Franchisee') && (
      <ReferenceInput
        fullWidth
        label="Professor"
        source="teacher"
        reference="teachers"
        alwaysOn
      >
        <SelectInput fullWidth optionText="name" />
      </ReferenceInput>
    )}
    <ReferenceInput
      fullWidth
      label="Turma"
      source="classroom"
      reference="classrooms"
      alwaysOn
    >
      <SelectInput fullWidth optionText="name" />
    </ReferenceInput>
  </Filter>
);
export default ResourceFilter;
