import React from 'react';
import { Edit, TextInput, SimpleForm, required } from 'react-admin';
import FormToolbar from '../components/FormToolbar';
import emailValidation from '../validations/emailValidation';

const SelectEdit = props => {
  if (
    props.record.entiny_type === 'Franchisee' ||
    props.record.entiny_type === 'School'
  ) {
    return (
      <SimpleForm {...props} toolbar={<FormToolbar />}>
        <TextInput
          fullWidth
          source="email"
          label="Email"
          validate={[required(), emailValidation]}
        />
        <TextInput
          fullWidth
          source="company"
          label="Razão social"
          validate={required()}
        />
        <TextInput
          fullWidth
          source="company_name"
          label="Nome fantasia"
          validate={required()}
        />
        <TextInput fullWidth source="cnpj" label="cnpj" />
      </SimpleForm>
    );
  }
  if (
    props.record.entiny_type === 'Teacher' ||
    props.record.entiny_type === 'Contributor'
  ) {
    return (
      <SimpleForm {...props} toolbar={<FormToolbar />}>
        <TextInput
          fullWidth
          source="email"
          label="Email"
          validate={[required(), emailValidation]}
        />
        <TextInput fullWidth source="name" label="Nome" validate={required()} />
      </SimpleForm>
    );
  }
};

const ProfileEdit = ({ staticContext, ...props }) => {
  return (
    <Edit
      id="my-profile"
      resource="profile"
      basePath="/my-profile"
      redirect={false}
      title="Meus dados"
      {...props}
    >
      <SelectEdit />
    </Edit>
  );
};

export default {
  edit: ProfileEdit
};
