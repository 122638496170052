import React from 'react';

import { List, Datagrid, TextField, EditButton } from 'react-admin';

import exporter from '../funcs/exporter';

import ResourceFilter from './Filters';

import ResourceActions from './Actions';

const ResourceList = ({ permissions, ...props }) => {
  return (
    <List
      bulkActionButtons={false}
      {...props}
      title={'Tema atividade em casa'}
      actions={<ResourceActions />}
      filters={<ResourceFilter permissions={permissions} />}
      exporter={exporter}
    >
      <Datagrid>
        <TextField label="Nome" source="name" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default ResourceList;
