import React, { useState, useEffect } from 'react';
import feathersClient from '../../feathersClient';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import GradePill from '../GradePill';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
    minWidth: 345,
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10
  },
  media: {
    height: 100,
    fontSize: 30,
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#3f51b5',
    fontFamily: 'Overpass'
    // backgroundColor: '#2196f3',
  }
});

export default props => {
  const [enable, setEnable] = useState(false);
  const [photoPermission, setPhotoPermission] = useState(true);
  const [contractedGrades, setContractedGrades] = useState([]);
  const [grades, setGrades] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    console.log('formData321', props.formData['contractedPrograms']);
    checkEnableProgram();
  }, []);

  const checkEnableProgram = () => {
    let contractedProgram = props.formData['contractedPrograms'].find(
      contractedProgram => contractedProgram.program == props.program._id
    );
    console.log('contractedProgram', contractedProgram);
    if (contractedProgram !== undefined) {
      if (contractedProgram.contractedGrades == undefined) {
        contractedProgram.contractedGrades = [];
      }
      console.log(
        'contractedProgram.contractedGrades',
        contractedProgram.contractedGrades
      );
      setPhotoPermission(contractedProgram.photoPermission);
      setContractedGrades([...contractedProgram.contractedGrades]);
      console.log('contractedGrades', contractedGrades);
      let _grades = [];
      props.program.grades.map(grade => {
        let __grade = props.grades.find(_grade => _grade._id == grade);
        if (__grade !== undefined) {
          _grades.push(__grade);
        }
      });
      setGrades(_grades);
      setEnable(true);
    } else {
      setEnable(false);
    }
  };

  const enableProgram = () => {
    props.formData['contractedPrograms'].push({ program: props.program._id });
    console.log(
      'props.record.contractedPrograms',
      props.formData['contractedPrograms']
    );
    checkEnableProgram();
  };

  const disableProgram = () => {
    let contractedPrograms = props.formData['contractedPrograms'].filter(
      contractedProgram => contractedProgram.program !== props.program._id
    );
    props.formData['contractedPrograms'] = contractedPrograms;
    setContractedGrades([]);
    checkEnableProgram();
  };

  const toogleContractGrade = grade => {
    let contractedGrade = contractedGrades.find(
      contractedGrade => contractedGrade == grade
    );

    props.formData['contractedPrograms'].map(contractedProgram => {
      if (contractedProgram.program == props.program._id) {
        if (contractedGrade == undefined) {
          if (contractedProgram.contractedGrades == undefined) {
            contractedProgram.contractedGrades = [];
          }
          contractedProgram.contractedGrades.push(grade);
        } else {
          contractedProgram.contractedGrades = contractedProgram.contractedGrades.filter(
            contractedGrade => contractedGrade !== grade
          );
        }
      }
    });
    checkEnableProgram();
  };

  const toogleContractPhotoPermission = _photoPermission => {
    props.formData['contractedPrograms'].map(contractedProgram => {
      if (contractedProgram.program == props.program._id) {
        contractedProgram.photoPermission = _photoPermission;
        setPhotoPermission(_photoPermission);
      }
    });
  };

  const checkEnableChip = grade => {
    console.log('graade', grade);
    console.log('graade inc', contractedGrades.includes(grade));
    // setChanged(changed + 1);
    return contractedGrades.includes(grade);
  };

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.media}>
            <Typography
              style={{ fontFamily: 'Overpass', fontSize: 35 }}
              gutterBottom
              variant="h5"
              component="h2"
            >
              {props.program.short_name}
            </Typography>
            <Typography
              style={{ fontFamily: 'Overpass' }}
              gutterBottom
              variant="h5"
              component="h2"
            >
              {props.program.name}
            </Typography>
          </div>
          {enable && (
            <>
              <Typography
                style={{
                  backgroundColor: '#2196f3',
                  padding: 5,
                  textAlign: 'center',
                  color: '#fff',
                  fontSize: 18,
                  marginBottom: 20,
                  fontWeight: 'bold'
                }}
                gutterBottom
                variant="h5"
                component="h2"
              >
                ANOS CONTRATADOS
              </Typography>
              {photoPermission !== null && (
                <Chip
                  style={{ marginBottom: 15, width: '100%' }}
                  label={'Permitir fotos'}
                  color={photoPermission ? 'primary' : 'null'}
                  onDelete={() => {
                    toogleContractPhotoPermission(!photoPermission);
                  }}
                  deleteIcon={photoPermission ? null : <DoneIcon />}
                />
              )}
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-around'
                }}
              >
                {grades
                  .sort((a, b) => a.grade - b.grade)
                  .map(grade => {
                    return (
                      <GradePill
                        grade={grade}
                        contractedGrades={contractedGrades}
                        toogleContractGrade={toogleContractGrade}
                      />
                    );
                    // return (
                    //   <div
                    //     style={{
                    //       backgroundColor: checkEnableChip(grade)
                    //         ? '#fff'
                    //         : '#e0e0e0',
                    //       color: checkEnableChip(grade) ? '#e0e0e0' : '#fff'
                    //     }}
                    //     onClick={() => {
                    //       toogleContractGrade(grade);
                    //     }}
                    //   >
                    //     {__grade.name}
                    //   </div>
                    // );
                  })}
              </div>
            </>
          )}
        </CardContent>

        <CardActions>
          {enable && (
            <Button
              size="small"
              color="secondary"
              onClick={() => {
                disableProgram();
              }}
            >
              Remover
            </Button>
          )}
          {enable == false && (
            <Button
              size="small"
              color="secondary"
              onClick={() => {
                enableProgram();
                // setEnabled(true);
              }}
            >
              Adicionar
            </Button>
          )}
        </CardActions>
      </Card>
    </>
  );
};
