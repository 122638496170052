import React from 'react';
import { Filter, TextInput } from 'react-admin';

const SchoolFilter = props => (
  <Filter {...props}>
    <TextInput
      fullWidth
      label='Razão Social'
      source='company[$search]'
      alwaysOn
    />
    <TextInput
      fullWidth
      label='Nome Fantasia'
      source='company_name[$search]'
      alwaysOn
    />
    {/* <TextInput fullWidth label="CNPJ" source="cnpj" alwaysOn /> */}
    {/* <TextInput fullWidth label="Email" source="email" alwaysOn /> */}
  </Filter>
);
export default SchoolFilter;
