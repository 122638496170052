import { restClient } from 'ra-data-feathers';
import feathersClient from '../feathersClient';
import myProfile from './myProfile';
import changeProfile from './changeProfile';
import changePassword from './changePassword';
import getGallery from './getGallery';
import { GET_ONE, GET_LIST, UPDATE } from 'react-admin';

const options = {
  id: '_id',
  usePatch: true,
  storageKey: 'feathers-jwt', // The key in localStorage used to store the authentication token
  authenticate: {
    // Options included in calls to Feathers client.authenticate
    strategy: 'local' // The authentication strategy Feathers should use
  },
  passwordField: 'password', // The key used to provide the password to Feathers client.authenticate
  usernameField: 'email', // The key used to provide the username to Feathers client.authenticate
  redirectTo: '/login' // Redirect to this path if an AUTH_CHECK fails. Uses the react-admin default of '/login' if omitted.
  // logoutOnForbidden: true // Logout when response status code is 403
};

const handleCustomProviders = dataProvider => async (
  verb,
  resource,
  params
) => {
  if (resource === 'gallery') {
    if (verb === GET_LIST) {
      const gallery = await getGallery();
      console.log('gallery', gallery);
      console.log('gallery.lenght', gallery.length);
      return Promise.resolve({ data: gallery, total: gallery.length });
    }
  }
  if (resource === 'profile') {
    if (verb === GET_ONE) {
      const getUser = await myProfile();
      console.log('aaa123', getUser);
      return Promise.resolve({ data: { ...getUser, id: params.id } });
    }
    if (verb === UPDATE) {
      const result = await changeProfile(params.data);

      return result
        ? Promise.resolve({ data: result })
        : Promise.reject({ data: params.data });
    }
  }
  if (resource === 'changepassword') {
    if (verb === GET_ONE) {
      const getUser = await myProfile();
      console.log('aqui');
      return Promise.resolve({ data: { ...getUser, id: params.id } });
    }
    if (verb === UPDATE) {
      const result = await changePassword(
        params.data.oldPassword,
        params.data.newPassword
      );

      return result
        ? Promise.resolve({ data: result })
        : Promise.reject({ data: params.data });
    }
  }
  return dataProvider(verb, resource, params);
};

export default handleCustomProviders(restClient(feathersClient, options));
