import Button from '@material-ui/core/Button';
import CloudUpload from '@material-ui/icons/CloudUpload';
import React, { useEffect, useState } from 'react';
import { FormDataConsumer, useNotify } from 'react-admin';
import Dropzone from 'react-dropzone';
import feathers from '../../feathersClient';
import PdfView from './components/PdfView';

import { useForm } from 'react-final-form';

const MaterialPdfUpload = (props) => {
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);

  const notify = useNotify();

  const form = useForm();

  // O useEffect abaixo checa quando o DOM carregou para setar o PDF URL e evitar re-render looping
  useEffect(() => {
    async function checkMount() {
      if (!props.fetched) {
        setPdfUrl(props.record.pdf);
      }
    }
    checkMount();
  }, [props]);

  const uploadFile = async (formData, data) => {
    setUploading(true);
    console.log('data', data);
    const file = data[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      // console.log(event.target.result);
      feathers
        .service('material-pdfs')
        .create({ pdf: event.target.result })
        .then((result) => {
          // console.log('result pdf', result);
          formData['pdf'] = result.pdf;
          setUploading(false);
          setUploaded(true);
          setPdfUrl(result.pdf);
          notify('Arquivo enviado, clique salvar para manter alteração');
          form.change('pdf', result.pdf);
          form.reset();
        });
    };
    reader.readAsDataURL(file);
  };

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}
    >
      <PdfView pdf={pdfUrl} />
      <FormDataConsumer>
        {({
          formData, // The whole form data
          getSource, // A function to get the valid source inside an ArrayInput,
          ...rest
        }) => {
          if (formData.pdf) {
            setPdfUrl(formData.pdf);
          }
          return (
            <>
              {uploading == true && (
                <div
                  style={{
                    top: 0,
                    left: 0,
                    position: 'absolute',
                    zIndex: 999,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      color: '#999',
                    }}
                  >
                    <img src='/loading.gif' />
                  </div>
                </div>
              )}

              <Dropzone
                // accept="pdf/*"
                maxFiles={1}
                onDrop={(acceptedFiles) => {
                  uploadFile(formData, acceptedFiles);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <input {...getInputProps()} />
                    <Button
                      {...getRootProps()}
                      variant='contained'
                      color='primary'
                      startIcon={<CloudUpload />}
                    >
                      Clique ou arraste um arquivo para
                      {pdfUrl !== undefined || uploaded
                        ? ' Alterar PDF'
                        : ' Subir PDF'}
                    </Button>
                  </section>
                )}
              </Dropzone>
            </>
          );
        }}
      </FormDataConsumer>
    </div>
  );
};

export default MaterialPdfUpload;
