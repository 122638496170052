import XLSX from 'xlsx';
import feathers from '../../../feathersClient';

const exporter = resources => {
  let data = [['Ano', 'Turma', 'Professor', 'Número de alunos']];
  Promise.all(
    resources.map(resource => {
      return new Promise(async (resolve, reject) => {
        let item = {
          gradeName: '',
          classroomName: '',
          teacherName: ''
        };
        await feathers
          .service('grades')
          .get(resource.grade)
          .then(grade => {
            item.gradeName = grade.name;
          })
          .catch(err => {});
        await feathers
          .service('teachers')
          .get(resource.teacher)
          .then(teacher => {
            item.teacherName = teacher.name;
          })
          .catch(err => {});

        item.classroomName = resource.name;

        data.push([...Object.values(item), resource.students.length]);
        resolve();
      });
    })
  ).then(() => {
    let worksheet = XLSX.utils.aoa_to_sheet(data);
    let new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'Turmas');
    XLSX.writeFile(new_workbook, `Turmas.xlsx`);
  });
};

export default exporter;
