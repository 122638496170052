import React, { useState } from 'react';
import { SelectInput } from 'react-admin';

const CitySelect = props => {
  console.log('props.field', props.field);
  const [prevState, setPrevState] = useState(
    props.formData[props.field] && props.formData[props.field].state_id
      ? props.formData[props.field].state_id
      : null
  );

  const getCitiesFor = (stateId, field) => {
    if (stateId[field] !== undefined && stateId[field].state_id !== undefined) {
      console.log('form', stateId[field]);
      console.log('stateId', stateId[field].state_id);
      console.log('prevState', prevState);
      if (prevState !== stateId[field].state_id) {
        setPrevState(stateId[field].state_id);
        stateId[field].city_id = 0;
        return [];
      } else {
        console.log('aqui');
        // if (stateId[field].city_id == null) {
        let selectedState = props.states.filter(
          state => state.id === stateId[field].state_id
        );
        if (selectedState.length > 0) {
          return selectedState[0].cities;
        } else {
          return [];
        }
        // }
      }
    }
  };
  return (
    <>
      <SelectInput
        fullWidth
        source={`${props.field}[city_id]`}
        choices={getCitiesFor(props.formData, props.field)}
        {...props}
      />
    </>
  );
};

export default CitySelect;
