import React, { useState, useEffect } from 'react';
import feathersClient from '../../feathersClient';
import ProgramCard from '../ProgramCard';
import { FormDataConsumer } from 'react-admin';

export default props => {
  console.log('props123', props);
  const [programs, setPrograms] = useState([]);
  const [grades, setGrades] = useState([]);
  useEffect(() => {
    feathersClient
      .service('programs')
      .find({ query: { $limit: '-1' } })
      .then(_programs => {
        setPrograms(_programs);
      });
    feathersClient
      .service('grades')
      .find({ query: { $limit: '-1' } })
      .then(_grades => {
        console.log('grades', _grades);
        setGrades(_grades);
      });
  }, []);

  return (
    <FormDataConsumer>
      {({
        formData, // The whole form data
        getSource, // A function to get the valid source inside an ArrayInput
        ...rest
      }) => {
        if (formData['contractedPrograms'] == undefined) {
          formData['contractedPrograms'] = [];
        }
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
              marginTop: 20
              // backgroundColor: '#eee'
            }}
          >
            {programs.length > 0 &&
              grades.length > 0 &&
              programs.map(program => (
                <ProgramCard
                  formData={formData}
                  {...props}
                  grades={grades}
                  program={program}
                />
              ))}
          </div>
        );
      }}
    </FormDataConsumer>
  );
};
