import React from 'react';

import { Edit, SimpleForm, TextInput } from 'react-admin';

export const ResourceEdit = ({ permissions, ...props }) => {
  return (
    <Edit title={'Áreas de atendimento'} {...props}>
      <SimpleForm>
        <TextInput source="name" label="Nome" />
      </SimpleForm>
    </Edit>
  );
};

export default ResourceEdit;
