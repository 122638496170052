import React from 'react';
import { Edit, email, required, SimpleForm, TextInput } from 'react-admin';
import GeneratePasswordButton from '../../../components/GeneratePasswordButton';
import emailValidation from '../../../validations/emailValidation';

const Title = ({ record }) => {
  return <span>{record ? `${record.name}` : ''}</span>;
};

const ResourceEdit = props => (
  <Edit {...props} title={<Title />}>
    <SimpleForm>
      <GeneratePasswordButton />
      <TextInput fullWidth source="name" label="Nome" validate={required()} />
      <TextInput
        fullWidth
        source="email"
        label="Email"
        validate={[required(), email(), emailValidation]}
      />
    </SimpleForm>
  </Edit>
);

export default ResourceEdit;
