import feathersClient from '../feathersClient';

export default (oldPassword, newPassword) => {
  return feathersClient
    .service('users')
    .get('me')
    .then(user => {
      return feathersClient
        .service('users')
        .patch(user._id, { oldPassword: oldPassword, newPassword: newPassword })
        .then(resource => {
          let data = { resource };
          console.log('data', data);
          return data;
        })
        .catch(err => {
          console.log('err', err);
          return false;
        });
    });
};
