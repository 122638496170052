import React from 'react';
import { Filter, TextInput } from 'react-admin';

const ResourceFilter = props => (
  <Filter {...props}>
    <TextInput fullWidth label='Nome' source='name' alwaysOn />
  </Filter>
);

export default ResourceFilter;
