import React from 'react';
import {
  Datagrid,
  DateField,
  EditButton,
  List,
  SingleFieldList,
  TextField,
} from 'react-admin';
import ZeiGradeField from '../../../components/ZeiGradeField';
import MaterialActions from './Actions';
import ResourceFilter from './Filter';
import TextArrayField from '../../../components/TextArrayField';

const ResourceList = (props) => {
  return (
    <List
      bulkActionButtons={false}
      {...props}
      title={'Materiais'}
      actions={<MaterialActions />}
      filters={<ResourceFilter />}
    >
      <Datagrid>
        {/* <TextField source="id" label="#" /> */}
        <TextField source='name' label='Atividade' />
        <TextField source='code' label='Cod Atividade' />
        <TextField source='grade_id' label='ZET Ano' />
        <TextField source='sequenceZet' label='Sequência ZET' />
        <TextField source='jornada_grade_id' label='J Ano' />
        <TextField source='sequenceJ' label='Sequência JornadaZ' />
        <TextField source='qrcode' label='qrcode' />
        <ZeiGradeField source='zei_grade_id' label='Volume Zei' />
        <DateField source='availableAt' label='Disponivel em' />
        <TextArrayField source='ageRange' label='Faixa etária'>
          <SingleFieldList>
            <TextField source='id' />
          </SingleFieldList>
        </TextArrayField>

        <EditButton />
      </Datagrid>
    </List>
  );
};

export default ResourceList;
