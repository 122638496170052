import feathersClient from '../feathersClient';

export default data => {
  delete data.id;
  let service = '';
  switch (data.entiny_type) {
    case 'School':
      service = 'schools';
      break;
    case 'Teacher':
      service = 'teachers';
      break;
    case 'Contributor':
      service = 'contributors';
      break;
    case 'Franchisee':
      service = 'franchisees';
      break;

    default:
      break;
  }
  return feathersClient
    .service(service)
    .patch(data._id, data)
    .then(resource => {
      let result = { resource };
      console.log('data', result);
      return result;
    })
    .catch(err => {
      console.log('err', err);
      return false;
    });
};
