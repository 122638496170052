import React from 'react';
import { SelectInput, Edit, SimpleForm, TextInput, email } from 'react-admin';

const Title = ({ record }) => {
  return <span>{record ? `${record.name}` : ''}</span>;
};

export const ResourceEdit = ({ permissions, ...props }) => {
  return (
    <>
      <Edit {...props} title={<Title />}>
        <SimpleForm>
          <TextInput fullWidth source='name' label='Nome' />
          <TextInput
            fullWidth
            source='email'
            label='E-mail'
            validate={[email()]}
          />
          <TextInput fullWidth source='school' label='Escola' />
          <SelectInput
            fullWidth
            source='userType'
            label='Tipo de usuário'
            choices={[
              { id: 'teacher', name: 'Professor' },
              { id: 'family', name: 'Família' },
            ]}
          />
        </SimpleForm>
      </Edit>
    </>
  );
};

export default ResourceEdit;
