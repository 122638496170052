import React from 'react';
import PropTypes from 'prop-types';

const ZeiGradeField = ({ source, record = {} }) => (
  <span>{record[source] ? parseInt(record[source]) - 10 : ''}</span>
);

ZeiGradeField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired
};

export default ZeiGradeField;
