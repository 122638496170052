import React from 'react';
import { Filter, SelectArrayInput, SelectInput, TextInput } from 'react-admin';

const ResourceFilter = props => (
  <Filter {...props}>
    <TextInput fullWidth label='Qrcode' source='qrcode' alwaysOn />
    <TextInput fullWidth label='Cod Atividade' source='code' alwaysOn />
    <TextInput
      fullWidth
      label='Jornada ano'
      source='jornada_grade_id'
      alwaysOn
    />
    <TextInput fullWidth label='Zet ano' source='grade_id' alwaysOn />
    <TextInput fullWidth label='Sequência Zet' source='sequenceZet' alwaysOn />
    <TextInput
      fullWidth
      label='Sequência JornadaZ'
      source='sequenceJ'
      alwaysOn
    />
    <SelectInput
      fullWidth
      alwaysOn
      source='zei_grade_id'
      label='Volume Zei'
      choices={[
        { id: null, name: 'Sem volume' },
        { id: 11, name: 'Volume 1' },
        { id: 12, name: 'Volume 2' },
        { id: 13, name: 'Volume 3' }
      ]}
    />
    <SelectInput
      fullWidth
      alwaysOn
      source='wh_grade_id'
      label='Em casa Ano'
      choices={[
        { id: null, name: 'Remover filto' },
        { id: 1, name: 'Ano 1' },
        { id: 2, name: 'Ano 2' },
        { id: 3, name: 'Ano 3' },
        { id: 4, name: 'Ano 4' },
        { id: 5, name: 'Ano 5' },
        { id: 6, name: 'Ano 6' },
        { id: 7, name: 'Ano 7' },
        { id: 8, name: 'Ano 8' },
        { id: 9, name: 'Ano 9' },
        { id: 11, name: 'Volume 1' },
        { id: 12, name: 'Volume 2' },
        { id: 13, name: 'Volume 3' }
      ]}
    />
    <SelectInput
      fullWidth
      alwaysOn
      source='wh_usersType'
      label='Em casa Para'
      choices={[
        { id: null, name: 'Remover filto' },
        { id: 'student', name: 'Aluno' },
        { id: 'family', name: 'Familiar' },
        { id: 'teacher', name: 'Professor' },
        { id: 'tips', name: 'Dicas professor' }
      ]}
    />

    <SelectInput
      fullWidth
      alwaysOn
      source='ageRange'
      label='Faixa etária'
      choices={[
        { id: null, name: 'Todos' },
        { id: 3, name: '3' },
        { id: 4, name: '4' },
        { id: 5, name: '5' },
        { id: 6, name: '6' },
        { id: 7, name: '7' },
        { id: 8, name: '8' },
        { id: 9, name: '9' },
        { id: 10, name: '10' },
        { id: 11, name: '11' },
        { id: 12, name: '12' },
        { id: 13, name: '13' },
        { id: 14, name: '14' },
        { id: 15, name: '15' }
      ]}
    />

    {/* <SelectInput
      fullWidth
      alwaysOn
      source=''
      label='Idade Máxima'
      choices={[
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
        { id: 4, name: '4' }
      ]}
    /> */}
  </Filter>
);

export default ResourceFilter;
