import React, { useState, useEffect } from 'react';
import feathersClient from '../../feathersClient';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
    minWidth: 345,
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10
  },
  media: {
    height: 100,
    fontSize: 30,
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#3f51b5',
    fontFamily: 'Overpass'
    // backgroundColor: '#2196f3',
  }
});

export default props => {
  const [enable, setEnable] = useState(false);

  const [contractedGrades, setContractedGrades] = useState();
  const classes = useStyles();

  return (
    <Chip
      style={{ marginBottom: 5 }}
      label={props.grade.name}
      color={
        props.contractedGrades.includes(props.grade._id) ? 'primary' : 'null'
      }
      onDelete={() => {
        props.toogleContractGrade(props.grade._id);
      }}
      deleteIcon={
        props.contractedGrades.includes(props.grade._id) ? null : <DoneIcon />
      }
    />
    // <div
    //   style={{
    //     backgroundColor: props.contractedGrades.includes(props.grade._id)
    //       ? '#fff'
    //       : '#e0e0e0',
    //     color: props.contractedGrades.includes(props.grade._id)
    //       ? '#e0e0e0'
    //       : '#fff'
    //   }}
    //   onClick={() => {
    //     props.toogleContractGrade(props.grade._id);
    //   }}
    // >
    //   {props.grade.name}
    // </div>
  );
};
