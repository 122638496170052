import {
  AppBar,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  TableCell,
  TableRow,
  TextField as MTextField,
  Toolbar,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useState } from 'react';
import {
  Datagrid,
  DatagridBody,
  Edit,
  FormTab,
  ReferenceArrayField,
  ReferenceInput,
  required,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput
} from 'react-admin';
import { useDropzone } from 'react-dropzone';
import XLSX from 'xlsx';
import feathers from '../../../feathersClient';

const ResourceEdit = ({ permissions, ...props }) => {
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [openedDialog, setOpenedDialog] = useState(false);
  const [toRemoveStudent, setToRemoveStudent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newStudent, setNewStudent] = useState('');
  const useStyles = makeStyles(theme => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    }
  }));
  const classes = useStyles();

  const MyDatagridRow = ({
    record,
    resource,
    id,
    onToggleItem,
    children,
    selected,
    basePath
  }) => (
    <TableRow key={id}>
      {/* data columns based on children */}
      {React.Children.map(children, field => (
        <TableCell key={`${id}-${field.props.source}`}>
          {React.cloneElement(field, {
            record,
            basePath,
            resource
          })}
        </TableCell>
      ))}
      <TableCell style={{ textAlign: 'right' }}>
        <Button
          style={{ borderColor: 'red', color: 'red' }}
          variant="outlined"
          onClick={() => {
            console.log('id', id);
            setToRemoveStudent(id);
            setOpenedDialog(true);
          }}
        >
          Remover
        </Button>
      </TableCell>
    </TableRow>
  );

  const MyDatagridBody = props => (
    <DatagridBody {...props} row={<MyDatagridRow />} />
  );

  const MyDatagrid = props => <Datagrid {...props} body={<MyDatagridBody />} />;

  const rowClick = event => {
    console.log('props', props);
    setDrawerOpened(true);
  };

  const closeDialog = () => {
    setToRemoveStudent(null);
    setOpenedDialog(false);
  };

  const listStudentsXlsx = id => {
    feathers
      .service('classrooms')
      .get(id)
      .then(classroom => {
        let students = [['Nome', 'Código']];
        Promise.all(
          classroom.students.map(student => {
            return new Promise((resolve, reject) => {
              feathers
                .service('students')
                .get(student)
                .then(student => {
                  students.push([student.name, student.code]);
                  resolve();
                })
                .catch(err => {});
            });
          })
        ).then(() => {
          console.log('students', students);
          let sheet = {
            cols: [
              { name: 'A', key: 0 },
              { name: 'B', key: 1 }
            ],
            data: students
          };
          let worksheet = XLSX.utils.aoa_to_sheet(students);
          let new_workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(new_workbook, worksheet, 'Alunos');
          XLSX.writeFile(new_workbook, `${classroom.name}.xlsx`);
        });
      })
      .catch(err => {
        setLoading(false);
      });
  };

  const removeStudent = () => {
    setLoading(true);
    feathers
      .service('classrooms')
      .get(props.id)
      .then(classroom => {
        console.log('classroom', classroom);
        let students = classroom.students.filter(
          student => student != toRemoveStudent
        );
        feathers
          .service('classrooms')
          .patch(props.id, { students: students })
          .then(classroom => {
            console.log('classroom', classroom);
            window.location.reload();
          })
          .catch(err => {
            setLoading(false);
          });
      })
      .catch(err => {
        setLoading(false);
      });
  };

  const ListButton = data => {
    return (
      <Button
        style={{ marginRight: 15 }}
        color="primary"
        variant="outlined"
        onClick={() => {
          listStudentsXlsx(data.id);
        }}
      >
        Listagem de alunos
      </Button>
    );
  };

  const MyDropzone = () => {
    const onDrop = useCallback(acceptedFiles => {
      setLoading(true);
      acceptedFiles.forEach(file => {
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          // Do whatever you want with the file contents
          var filename = file.name;
          // pre-process data
          var binary = '';
          var bytes = new Uint8Array(reader.result);
          var length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          // call 'xlsx' to read the file
          const oFile = XLSX.read(binary, {
            type: 'binary',
            cellDates: true,
            cellStyles: true
          });
          const first_worksheet = oFile.Sheets[oFile.SheetNames[0]];
          const data = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });
          data.splice(0, 1);
          console.log('data', data);

          Promise.all(
            data.map(student => {
              console.log('student', student);
              if (student.length > 0) {
                return new Promise((resolve, reject) => {
                  if (student[1] !== undefined && student[1].length > 0) {
                    console.log('student[1]', student[1]);
                    feathers
                      .service('students')
                      .find({
                        query: { code: student[1].toUpperCase(), $limit: '-1' }
                      })
                      .then(_student => {
                        console.log('stu', _student[0]);
                        if (_student.length > 0) {
                          feathers
                            .service('students')
                            .patch(_student[0]._id, { name: student[0] })
                            .then(__student => {
                              console.log('student', __student);
                              resolve();
                            });
                        } else {
                          resolve();
                        }
                      });
                  } else {
                    feathers
                      .service('students')
                      .create({ name: student[0] })
                      .then(__student => {
                        console.log('student', __student);
                        feathers
                          .service('classrooms')
                          .patch(props.id, {
                            $push: { students: __student._id }
                          })
                          .then(classroom => {
                            console.log('classroom', classroom);
                            resolve();
                          });
                      });
                  }
                });
              }
            })
          ).then(() => {
            console.log('finish');
            // set loading false
            setLoading(false);
            window.location.reload();
          });
        };
        reader.readAsArrayBuffer(file);
      });
    }, []);
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} accept=".xlsx" />
        <Button style={{ marginRight: 15 }} color="primary" variant="outlined">
          Adicionar alunos em lote
        </Button>
      </div>
    );
  };

  const Title = ({ record }) => {
    return <span>{record ? `${record.name}` : ''}</span>;
  };
  return (
    <>
      <Edit title={<Title />} {...props}>
        <TabbedForm>
          <FormTab label="Dados">
            <TextInput
              fullWidth
              source="name"
              label="Turma"
              validate={required()}
            />
            <ReferenceInput
              fullWidth
              label="Ano"
              source="grade"
              reference="grades"
              validate={required()}
              disabled={
                permissions == 'School' || permissions == 'Teacher'
                  ? true
                  : false
              }
            >
              <SelectInput validate={required()} fullWidth optionText="name" />
            </ReferenceInput>
            {(permissions == 'School' || permissions == 'Contributor') && (
              <ReferenceInput
                fullWidth
                label="Professor"
                source="teacher"
                reference="teachers"
                validate={required()}
              >
                <SelectInput
                  validate={required()}
                  fullWidth
                  optionText="name"
                />
              </ReferenceInput>
            )}
          </FormTab>

          <FormTab label="Alunos">
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
            >
              <ListButton {...props} />
              <MyDropzone />

              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  setDrawerOpened(true);
                }}
              >
                Novo Aluno
              </Button>
            </div>
            <ReferenceArrayField
              label=""
              reference="students"
              source="students"
              fullWidth
            >
              <MyDatagrid fullWidth>
                <TextField label="Nome" source="name" fullWidth />
                <TextField label="Código" source="code" fullWidth />
              </MyDatagrid>
            </ReferenceArrayField>
          </FormTab>
        </TabbedForm>
      </Edit>
      <Dialog open={openedDialog}>
        <DialogTitle>"Deseja remover o aluno da turma?"</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseja remover o aluno da turma ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              closeDialog();
            }}
            color="primary"
          >
            Não
          </Button>
          <Button
            onClick={() => {
              removeStudent();
            }}
            color="primary"
            autoFocus
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
      <Drawer anchor="right" open={drawerOpened}>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div
          style={{
            maxWidth: 400,
            minWidth: 300,
            padding: 20,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <AppBar position="static" style={{ marginBottom: 20 }}>
            <Toolbar>
              <Typography variant="h6">Novo Aluno</Typography>
            </Toolbar>
          </AppBar>
          <MTextField
            value={newStudent}
            label="Nome"
            onChange={event => {
              console.log('value', event.target.value);
              setNewStudent(event.target.value);
            }}
          />

          <div
            style={{
              display: 'flex',
              marginTop: 20,
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <Button
              style={{ marginRight: 10 }}
              color="primary"
              variant="contained"
              onClick={() => {
                setLoading(true);
                feathers
                  .service('students')
                  .create({ name: newStudent })
                  .then(student => {
                    console.log('student', student);
                    feathers
                      .service('classrooms')
                      .patch(props.id, { $push: { students: student._id } })
                      .then(classroom => {
                        setLoading(false);
                        console.log('classroom', classroom);
                        window.location.reload();
                      })
                      .catch(err => {
                        setLoading(false);
                      });
                  })
                  .catch(err => {
                    setLoading(false);
                  });
              }}
            >
              Adicionar
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              variant="contained"
              onClick={() => {
                setDrawerOpened(false);
              }}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default ResourceEdit;
