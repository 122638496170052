import XLSX from 'xlsx';

const exporter = resources => {
  let data = [['Nome', 'Email']];
  Promise.all(
    resources.map(resource => {
      return new Promise(async (resolve, reject) => {
        data.push([resource.name, resource.email]);
        resolve();
      });
    })
  ).then(() => {
    let worksheet = XLSX.utils.aoa_to_sheet(data);
    let new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'Professores');
    XLSX.writeFile(new_workbook, `Professores.xlsx`);
  });
};

export default exporter;
