import React from 'react';
import {
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  ReferenceField,
  TextField
} from 'react-admin';
import DateField from '../../../components/DateField/index';

import ResourceActions from '../components/Actions';
import ResourceFilter from '../components/Filter';
import exporter from '../funcs/exporter';

const ResourceList = ({ permissions, ...props }) => (
  <List
    bulkActionButtons={false}
    {...props}
    title={'Cronogramas'}
    actions={<ResourceActions />}
    filters={<ResourceFilter permissions={permissions} />}
    exporter={exporter}
  >
    <Datagrid>
      {/* {permissions === 'Contributor' && <TextField source="id" label="#" />} */}
      <ReferenceField label="Atividade" source="material" reference="materials">
        <TextField source="name" />
      </ReferenceField>
      {(permissions === 'Contributor' || permissions === 'School') && (
        <ReferenceField
          label="Professor"
          source="teacher"
          reference="teachers"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
      )}
      <ReferenceField label="Turma" source="classroom" reference="classrooms">
        <TextField source="name" />
      </ReferenceField>
      <DateField label="Data" source="scheduleAt" showTime />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);
export default ResourceList;
