import React from 'react';
import { Datagrid, EditButton, List, TextField } from 'react-admin';
import exporter from '../funcs/exporter';
import ResourceFilter from './Filter';
import ResourceActions from './Actions';

const ResourceList = props => (
  <List
    bulkActionButtons={false}
    {...props}
    title={'Franquias'}
    filters={<ResourceFilter />}
    actions={<ResourceActions />}
    exporter={exporter}
  >
    <Datagrid>
      {/* <TextField source="id" label="#" /> */}
      <TextField source='company' label='Razão social' />
      <TextField source='company_name' label='Nome fantasia' />
      <TextField source='cnpj' />
      <TextField source='email' />
      <EditButton />
    </Datagrid>
  </List>
);

export default ResourceList;
