import React from 'react';
import { List } from 'react-admin';
import ImageList from '../../../components/ImageList';

import ResourceActions from '../components/Actions';

const MaterialImageList = (_props) => {
  console.log('_props', _props);
  if (_props.data !== undefined) {
    const activities = Object.keys(_props.data).map((key) => {
      return _props.data[key];
    });
    return (
      <>
        <ImageList activities={activities} />
      </>
    );
  } else {
    return <div></div>;
  }
};

const ResourceList = ({ permissions, ...props }) => {
  console.log('props', props);
  console.log('permissions', permissions);
  return (
    <List
      bulkActionButtons={false}
      {...props}
      title={'Galeria de Fotos'}
      actions={<ResourceActions />}
      pagination={null}
    >
      <MaterialImageList />
    </List>
  );
};
export default ResourceList;
