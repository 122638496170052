import feathersClient from '../feathersClient';

export default () => {
  return feathersClient
    .service('jornadaz/activities')
    .find({ query: { $limit: '-1' } })
    .then(activities => {
      return activities.map(gallery => {
        return { ...gallery, id: gallery._id };
      });
    });
};
