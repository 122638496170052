import feathers from '../../../feathersClient';
import XLSX from 'xlsx';

const exporter = resources => {
  let data = [
    [
      'Atividade',
      'Programa',
      'Professor',
      'Turma',
      'Feita em',
      'Número de alunos',
      'Número de imagens'
    ]
  ];
  Promise.all(
    resources.map(resource => {
      return new Promise(async (resolve, reject) => {
        let item = {
          materialName: '',
          programName: '',
          teacherName: '',
          classroomName: '',
          buildedAt: '',
          studentsNum: 0,
          imagesNum: 0
        };
        await feathers
          .service('materials')
          .get(resource.material)
          .then(material => {
            item.materialName = material.name;
          })
          .catch(err => {});
        await feathers
          .service('programs')
          .get(resource.program)
          .then(program => {
            item.programName = program.short_name;
          })
          .catch(err => {});
        await feathers
          .service('teachers')
          .get(resource.teacher)
          .then(teacher => {
            item.teacherName = teacher.name;
          })
          .catch(err => {});
        await feathers
          .service('teachers')
          .get(resource.teacher)
          .then(teacher => {
            item.teacherName = teacher.name;
          })
          .catch(err => {});
        await feathers
          .service('classrooms')
          .get(resource.classroom)
          .then(classroom => {
            item.classroomName = classroom.name;
          })
          .catch(err => {});
        let buildedAt = new Date(resource.buildedAt);
        let formatedBuildedAt = `${buildedAt.getDate()}/${buildedAt.getMonth()}/${buildedAt.getFullYear()} ${buildedAt.getHours()}:${buildedAt.getMinutes()}`;
        data.push([
          ...Object.values(item),
          formatedBuildedAt,
          resource.students.length,
          resource.images.length
        ]);
        resolve();
      });
    })
  ).then(() => {
    let worksheet = XLSX.utils.aoa_to_sheet(data);
    let new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'Atividades');
    XLSX.writeFile(new_workbook, `Atividades.xlsx`);
  });
};

export default exporter;
