import React from 'react';
import {
  Create,
  FormTab,
  ReferenceInput,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
} from 'react-admin';

const ResourceCreate = ({ permissions, ...props }) => {
  const Title = ({ record }) => {
    return <span>{record.name ? `${record.name}` : 'Nova Turma'}</span>;
  };
  return (
    <>
      <Create title={<Title />} {...props}>
        <TabbedForm>
          <FormTab label='Dados'>
            <TextInput
              fullWidth
              source='name'
              label='Turma'
              validate={required()}
            />
            <ReferenceInput
              fullWidth
              label='Ano'
              source='grade'
              reference='contracted-grades'
              validate={required()}
            >
              <SelectInput fullWidth validate={required()} optionText='name' />
            </ReferenceInput>
            {(permissions == 'School' || permissions == 'Contributor') && (
              <ReferenceInput
                fullWidth
                label='Professor'
                source='teacher'
                reference='teachers'
                validate={required()}
              >
                <SelectInput
                  fullWidth
                  validate={required()}
                  optionText='name'
                />
              </ReferenceInput>
            )}
          </FormTab>
        </TabbedForm>
      </Create>
    </>
  );
};

export default ResourceCreate;
