import React from 'react';
import { Admin, Resource, Login } from 'react-admin';
import portugueseMessages from 'ra-language-portuguese';
import { restClient } from 'ra-data-feathers';
import feathersClient from './feathersClient';
import providers from './providers';
import profile from './models/profile';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import changepassword from './models/changepassword';
import {
  List as SchoolList,
  Create as SchoolCreate,
  Edit as SchoolEdit,
} from './models/schools/index';
import {
  List as FranchiseeList,
  Create as FranchiseeCreate,
  Edit as FranchiseeEdit,
} from './models/franchisees/index';
import { Route } from 'react-router-dom';
import {
  List as ClassroomList,
  Create as ClassroomCreate,
  Edit as ClassroomEdit,
  Show as ClassroomShow,
} from './models/classrooms/index';
import {
  List as ActivityList,
  Edit as ActivityEdit,
} from './models/activities/index';
import {
  List as HomeActivityList,
  Create as HomeActivityCreate,
  Edit as HomeActivityEdit,
  Show as HomeActivityShow,
} from './models/homeActivities/index';
// import {
//   Create as AttendAreasCreate,
//   List as AttendAreasList,
//   Edit as AttendAreasEdit
// } from './models/attendAreas';
// import {
//   Create as AdvisorsCreate,
//   List as AdvisorsList,
//   Edit as AdvisorsEdit
// } from './models/advisors';
import {
  List as TeacherList,
  Edit as TeacherEdit,
  Create as TeacherCreate,
} from './models/teachers/index';
import {
  List as ProgramList,
  Edit as ProgramEdit,
  Create as ProgramCreate,
} from './models/programs/index';
import {
  List as WHThemeList,
  Edit as WHThemeEdit,
  Create as WHThemeCreate,
} from './models/wh-themes/index';
import {
  List as FutureWorkList,
  Create as FutureWorkCreate,
  Edit as FutureWorkEdit,
} from './models/futureWorks/index';
import {
  List as MaterialList,
  Create as MaterialCreate,
  Edit as MaterialEdit,
} from './models/materials/index';
import {
  List as HoTipsList,
  Create as HoTipsCreate,
  Edit as HoTipsEdit,
} from './models/homeOfficeTips/index';
import { List as GalleryList } from './models/gallery/index';
import './App.css';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import authClient from './AuthClient';
import MyLayout from './customs/MyLayout';
import Grade from '@material-ui/icons/Grade';
import School from '@material-ui/icons/School';
import Timeline from '@material-ui/icons/Timeline';
import Group from '@material-ui/icons/Group';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import PhotoLibrary from '@material-ui/icons/PhotoLibrary';
import Business from '@material-ui/icons/Business';
import HomeWork from '@material-ui/icons/HomeWork';
import Home from '@material-ui/icons/Home';
import HomeOutlined from '@material-ui/icons/HomeOutlined';
import SchoolOutlined from '@material-ui/icons/SchoolOutlined';
import canDo from './canDo';
import { usePermissions } from 'react-admin';

const authClientOptions = {
  storageKey: 'feathers-jwt',
  authenticate: { strategy: 'local' },
  permissionsKey: 'permissions', // The key in localStorage used to store permissions from decoded JWT
  permissionsField: 'entiny_type', // The key in the decoded JWT containing the user's role
};

// to rename id field for *all* resources use this syntax:
// const options = {  };
//

// portugueseMessages['ra']['navigation']['page_rows_per_page'] =
//   'Resultados por página';

// const messages = {
//   'pt-br': portugueseMessages
// };
// console.log('messages', messages);

const i18nProvider = polyglotI18nProvider(() => portugueseMessages, 'pt');

const MyLoginPage = () => <Login backgroundImage='/3back.png' />;

const App = () => {
  const { permissions } = usePermissions();
  return (
    <Admin
      // locale="pt"
      title=''
      loginPage={MyLoginPage}
      i18nProvider={i18nProvider}
      authProvider={authClient(feathersClient, authClientOptions)}
      dataProvider={providers}
      appLayout={MyLayout}
      customRoutes={[
        <Route key='my-profile' path='/my-profile' component={profile.edit} />,
        <Route
          key='my-profile'
          path='/change-password'
          component={changepassword.edit}
        />,
      ]}
    >
      {(permissions) => [
        <Resource
          options={{ label: 'Franquias' }}
          name='franchisees'
          list={
            canDo(permissions, 'franchisees', 'list') ? FranchiseeList : null
          }
          create={
            canDo(permissions, 'franchisees', 'create')
              ? FranchiseeCreate
              : null
          }
          edit={
            canDo(permissions, 'franchisees', 'edit') ? FranchiseeEdit : null
          }
          icon={Business}
        />,
        <Resource
          options={{ label: 'Escolas' }}
          name='schools'
          list={canDo(permissions, 'schools', 'list') ? SchoolList : null}
          create={canDo(permissions, 'schools', 'create') ? SchoolCreate : null}
          edit={canDo(permissions, 'schools', 'edit') ? SchoolEdit : null}
          icon={SchoolOutlined}
        />,
        <Resource
          options={{ label: 'Temas atividade em casa' }}
          name='wh-themes'
          list={canDo(permissions, 'whthemes', 'list') ? WHThemeList : null}
          create={
            canDo(permissions, 'whthemes', 'create') ? WHThemeCreate : null
          }
          edit={canDo(permissions, 'whthemes', 'edit') ? WHThemeEdit : null}
          icon={Home}
        />,
        <Resource
          options={{ label: 'Cadastro atividade em casa' }}
          name='wh-registers'
          list={
            canDo(permissions, 'whregisters', 'list') ? HomeActivityList : null
          }
          create={
            canDo(permissions, 'whregisters', 'create')
              ? HomeActivityCreate
              : null
          }
          edit={
            canDo(permissions, 'whregisters', 'edit') ? HomeActivityEdit : null
          }
          icon={HomeOutlined}
        />,
        // <Resource
        //   options={{ label: 'Orientadores' }}
        //   name="advisors"
        //   list={AdvisorsList}
        //   create={AdvisorsCreate}
        //   edit={AdvisorsEdit}
        //   icon={DeveloperBoardIcon}
        // />,
        <Resource
          options={{ label: 'Atividades' }}
          name='jornadaz/activities'
          list={ActivityList}
          edit={ActivityEdit}
          icon={Grade}
        />,
        <Resource
          options={{ label: 'Cronogramas' }}
          name='future-works'
          create={FutureWorkCreate}
          list={FutureWorkList}
          edit={FutureWorkEdit}
          icon={Timeline}
        />,
        canDo(permissions, 'teachers', 'default') ? (
          <Resource
            options={{ label: 'Professores' }}
            name='teachers'
            list={TeacherList}
            create={TeacherCreate}
            edit={TeacherEdit}
            icon={School}
          />
        ) : null,
        canDo(permissions, 'programs', 'default') ? (
          <Resource
            options={{ label: 'Programas' }}
            name='programs'
            // list={ProgramList}
            // create={ProgramCreate}
            // edit={ProgramEdit}
            // icon={DeveloperBoardIcon}
          />
        ) : (
          <Resource options={{ label: 'Programas' }} name='programs' />
        ),
        // <Resource
        //   options={{ label: 'Áreas de atendimento' }}
        //   name="attend-areas"
        //   list={AttendAreasList}
        //   create={AttendAreasCreate}
        //   edit={AttendAreasEdit}
        //   icon={DeveloperBoardIcon}
        // />,
        <Resource
          options={{ label: 'Turmas' }}
          name='classrooms'
          create={ClassroomCreate}
          list={ClassroomList}
          edit={ClassroomEdit}
          icon={Group}
        />,
        <Resource options={{ label: 'Anos' }} name='grades' />,
        <Resource options={{ label: 'Alunos' }} name='students' />,
        <Resource
          options={{ label: 'Sequencia didatico' }}
          name='didactic-sequences'
        />,
        <Resource
          options={{ label: 'Materiais' }}
          name='materials'
          create={
            canDo(permissions, 'materials', 'create') ? MaterialCreate : null
          }
          list={canDo(permissions, 'materials', 'list') ? MaterialList : null}
          edit={canDo(permissions, 'materials', 'edit') ? MaterialEdit : null}
        />,
        canDo(permissions, 'gallery', 'default') ? (
          <Resource
            options={{ label: 'Galeria de Fotos' }}
            name='gallery'
            list={GalleryList}
            icon={PhotoLibrary}
          />
        ) : null,
        <Resource
          options={{ label: 'Dicas Home Office' }}
          name='ho-tips'
          list={canDo(permissions, 'hoTips', 'list') ? HoTipsList : null}
          create={canDo(permissions, 'hoTips', 'create') ? HoTipsCreate : null}
          edit={canDo(permissions, 'hoTips', 'edit') ? HoTipsEdit : null}
          icon={HomeWork}
        />,
        <Resource
          options={{ label: 'Anos contratados' }}
          name='contracted-grades'
        />,
        <Resource name='profile' />,
        <Resource name='changepassword' />,
      ]}
    </Admin>
  );
};

export default App;
