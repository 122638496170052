import React, { useState } from 'react';
import { Button } from 'react-admin';
import feathers from '../../feathersClient';
import MButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const GeneratePasswordButton = props => {
  const [open, setOpen] = useState(false);
  const resetPassword = 'inicial@123';

  const changePassword = async () => {
    feathers
      .service('users')
      .patch(props.record.userId, { resetPassword: resetPassword })
      .then(user => {
        console.log('user', user);
        console.log('password changed', resetPassword);
        setOpen(true);
        // open modal with password
      });
  };
  console.log('props button', props);
  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Nova senha</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Sua nova senha é ${resetPassword} a senha pode ser alterada pelo perfil em alterar senha.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MButton
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
            autoFocus
          >
            OK
          </MButton>
        </DialogActions>
      </Dialog>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          width: '100%',
          flexDirection: 'column',
          marginTop: 10,
          marginBottom: 10
        }}
      >
        <Button
          label="Gerar nova senha"
          onClick={() => {
            changePassword();
          }}
        />
      </div>
    </>
  );
};

export default GeneratePasswordButton;
