import BookIcon from '@material-ui/icons/Book';
import RichTextInput from 'ra-input-rich-text';
import React from 'react';
import {
  Create,
  DateInput,
  FormDataConsumer,
  FormTab,
  NumberInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  TabbedForm,
  TextInput,
} from 'react-admin';
import MaterialImageUpload from '../../../components/MaterialImageUpload';
import MaterialPdfUpload from '../../../components/MaterialPdfUpload';
import MaterialVideoUpload from '../../../components/MaterialVideoUpload';

const ResourceCreate = (props) => {
  const dateParser = (v) => {
    let time = v;
    time += 'T04:00';
    if (v.charAt(0) != '0') {
      return time;
    }
    return v;
  };
  const uploadFile = (data) => {
    console.log('data', data);
  };

  const validateMaterial = (values) => {
    const errors = {};
    if (!values.text && !values.pdf && !values.thumb && !values.zvideo) {
      errors.text = errors.pdf = errors.thumb = errors.zvideo = [
        'É necessário ao menos 1 material',
      ];
    }
    return errors;
  };

  return (
    <Create {...props}>
      <TabbedForm validate={validateMaterial}>
        {/* <BooleanInput source='status' label='Status' /> */}
        <FormTab label='Dados'>
          <TextInput
            fullWidth
            source='name'
            label='Nome'
            validate={[required()]}
          />
          <TextInput fullWidth source='zoomId' label='Nome da montagem' />
          <TextInput fullWidth source='code' label='Código da Atividade' />
        </FormTab>

        <FormTab
          label={
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                if (
                  formData.grade_id ||
                  formData.sequenceZet ||
                  formData.qrcode
                ) {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <p>ZET</p>
                      <BookIcon />
                    </div>
                  );
                } else {
                  return 'ZET';
                }
              }}
            </FormDataConsumer>
          }
        >
          <NumberInput fullWidth source='grade_id' label='ZET Ano' />
          <TextInput
            fullWidth
            source='sequenceZet'
            label='Sequência de atividade ZET'
          />
          <TextInput fullWidth source='qrcode' />
        </FormTab>

        <FormTab label='Jornadaz'>
          <NumberInput
            fullWidth
            source='jornada_grade_id'
            label='Jornada Ano'
          />
          <TextInput
            fullWidth
            source='sequenceJ'
            label='Sequência de atividade JornadaZ'
          />
        </FormTab>

        <FormTab label='Zei'>
          <SelectInput
            fullWidth
            source='zei_grade_id'
            label='Volume Zei'
            choices={[
              { id: null, name: 'Sem volume' },
              { id: 11, name: 'Volume 1' },
              { id: 12, name: 'Volume 2' },
              { id: 13, name: 'Volume 3' },
            ]}
          />
          <ReferenceInput
            fullWidth
            label='Sequencia didatica'
            source='didacticSequence'
            reference='didactic-sequences'
          >
            <SelectInput fullWidth optionText='name' />
          </ReferenceInput>
          <NumberInput
            fullWidth
            source='didacticSequenceOrder'
            label='Ordem Zei'
          />
        </FormTab>

        <FormTab label='Trabalho em Casa'>
          <SelectInput
            fullWidth
            source='workInHouse'
            label='Trabalho em casa'
            choices={[
              { id: false, name: 'Não' },
              { id: true, name: 'Sim' },
            ]}
          />
          <SelectInput
            fullWidth
            source='wh_grade_id'
            label='Ano / volume atividade em casa'
            choices={[
              { id: 1, name: 'Ano 1' },
              { id: 2, name: 'Ano 2' },
              { id: 3, name: 'Ano 3' },
              { id: 4, name: 'Ano 4' },
              { id: 5, name: 'Ano 5' },
              { id: 6, name: 'Ano 6' },
              { id: 7, name: 'Ano 7' },
              { id: 8, name: 'Ano 8' },
              { id: 9, name: 'Ano 9' },
              { id: 11, name: 'Volume 1' },
              { id: 12, name: 'Volume 2' },
              { id: 13, name: 'Volume 3' },
            ]}
          />
          <SelectArrayInput
            fullWidth
            source='wh_usersType'
            label='Atividade para: (Atividade de casa)'
            choices={[
              { id: 'student', name: 'Aluno' },
              { id: 'family', name: 'Familia' },
              { id: 'teacher', name: 'Professor' },
              { id: 'tips', name: 'Dicas de home office' },
            ]}
          />
          <ReferenceInput
            fullWidth
            label='Tema atividade em casa'
            source='wh_theme'
            reference='wh-themes'
          >
            <SelectInput fullWidth optionText='name' />
          </ReferenceInput>
          <DateInput
            fullWidth
            source='availableAt'
            label='Disponivel em'
            options={{
              format: 'DD/MM/YYYY, HH:mm:ss',
              ampm: false,
              clearable: true,
            }}
            parse={dateParser}
          />
          <SelectArrayInput
            fullWidth
            alwaysOn
            source='ageRange'
            label='Faixa etária'
            choices={[
              { id: 3, name: '3' },
              { id: 4, name: '4' },
              { id: 5, name: '5' },
              { id: 6, name: '6' },
              { id: 7, name: '7' },
              { id: 8, name: '8' },
              { id: 9, name: '9' },
              { id: 10, name: '10' },
              { id: 11, name: '11' },
              { id: 12, name: '12' },
              { id: 13, name: '13' },
              { id: 14, name: '14' },
              { id: 15, name: '15' },
            ]}
          />
          <SelectInput
            fullWidth
            source='wh_student_position'
            label='Posição mapa atividade Aluno'
            choices={[
              { id: 1, name: '1' },
              { id: 2, name: '2' },
              { id: 3, name: '3' },
              { id: 4, name: '4' },
              { id: 5, name: '5' },
              { id: 6, name: '6' },
              { id: 7, name: '7' },
              { id: 8, name: '8' },
              { id: 9, name: '9' },
              { id: 10, name: '10' },
              { id: 11, name: '11' },
              { id: 12, name: '12' },
              { id: 13, name: '13' },
              { id: 14, name: '14' },
              { id: 15, name: '15' },
            ]}
          />
          <SelectInput
            fullWidth
            source='wh_family_position'
            label='Posição mapa atividade Familia'
            choices={[
              { id: 1, name: '1' },
              { id: 2, name: '2' },
              { id: 3, name: '3' },
              { id: 4, name: '4' },
              { id: 5, name: '5' },
              { id: 6, name: '6' },
              { id: 7, name: '7' },
              { id: 8, name: '8' },
              { id: 9, name: '9' },
              { id: 10, name: '10' },
              { id: 11, name: '11' },
              { id: 12, name: '12' },
              { id: 13, name: '13' },
              { id: 14, name: '14' },
              { id: 15, name: '15' },
            ]}
          />
          <SelectInput
            fullWidth
            source='wh_teacher_position'
            label='Posição mapa atividade Professor'
            choices={[
              { id: 1, name: '1' },
              { id: 2, name: '2' },
              { id: 3, name: '3' },
              { id: 4, name: '4' },
              { id: 5, name: '5' },
              { id: 6, name: '6' },
              { id: 7, name: '7' },
              { id: 8, name: '8' },
              { id: 9, name: '9' },
              { id: 10, name: '10' },
              { id: 11, name: '11' },
              { id: 12, name: '12' },
              { id: 13, name: '13' },
              { id: 14, name: '14' },
              { id: 15, name: '15' },
            ]}
          />
        </FormTab>

        {/* <NumberInput fullWidth source="" label="Volume Zei" /> */}
        <FormTab
          label={
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                if (
                  !formData.text &&
                  !formData.pdf &&
                  !formData.thumb &&
                  !formData.zvideo
                ) {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        alignContent: 'center',
                      }}
                    >
                      <p style={{ maxHeight: 5 }}>MATERIAL</p>
                      <p style={{ maxHeight: 2, fontSize: 9, color: 'red' }}>
                        Ao menos 1 material é necessário
                      </p>
                    </div>
                  );
                } else {
                  return 'MATERIAL';
                }
              }}
            </FormDataConsumer>
          }
        >
          <RichTextInput
            label='Texto'
            source='text'
            toolbar={[
              ['bold', 'italic', 'underline', 'strike'], // toggled buttons
              ['blockquote', 'code-block'],

              [{ header: 1 }, { header: 2 }], // custom button values
              [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
              [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
              [{ direction: 'rtl' }], // text direction

              [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
              [{ header: [1, 2, 3, 4, 5, 6, false] }],

              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              [{ align: [] }],

              ['clean'],
            ]}
            validation={{ required: true }}
          />
          {/* <TextInput fullWidth multiline={true} source="text" label="Texto" /> */}
          <MaterialPdfUpload {...props} />
          <MaterialImageUpload {...props} />
          <MaterialVideoUpload {...props} />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default ResourceCreate;
