import React from 'react';
import { Datagrid, EditButton, List, TextField } from 'react-admin';

import ResourceActions from './Actions';
import ResourceFilter from './Filter';
import exporter from '../funcs/exporter';

const ResourceList = ({ permissions, ...props }) => (
  <List
    bulkActionButtons={false}
    {...props}
    title={'Professores'}
    actions={<ResourceActions />}
    filters={<ResourceFilter />}
    exporter={exporter}
  >
    <Datagrid>
      <TextField source='name' label='Nome' />
      <TextField source='email' label='Email' />
      <EditButton />
    </Datagrid>
  </List>
);

export default ResourceList;
