import React from 'react';

import {
  TextInput,
  Create,
  SimpleForm,
  BooleanInput,
  SelectInput,
  ReferenceInput,
  email,
  required,
} from 'react-admin';

export const ResourceCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput fullWidth source='name' label='Nome' />
      <TextInput fullWidth source='email' label='E-mail' validate={[email()]} />
      <TextInput fullWidth source='school' label='Escola' />
      <SelectInput
        fullWidth
        source='userType'
        label='Tipo de usuário'
        choices={[
          { id: 'teacher', name: 'Professor' },
          { id: 'family', name: 'Família' },
        ]}
      />
    </SimpleForm>
  </Create>
);

export default ResourceCreate;
