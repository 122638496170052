import React from 'react';
import {
  Datagrid,
  EditButton,
  List,
  ReferenceField,
  TextField
} from 'react-admin';
import ResourceActions from './Actions';
import exporter from '../funcs/exporter';

const ResourceList = ({ permissions, ...props }) => (
  <List
    bulkActionButtons={false}
    {...props}
    title={'Turmas'}
    actions={<ResourceActions />}
    exporter={exporter}
  >
    <Datagrid>
      {/* {permissions === 'Contributor' && <TextField source="id" label="#" />} */}
      <ReferenceField
        label="Ano"
        source="grade"
        reference="grades"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" label="Turma" />
      {(permissions === 'Contributor' || permissions === 'School') && (
        <ReferenceField
          label="Professor"
          source="teacher"
          reference="teachers"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
      )}

      <EditButton />
    </Datagrid>
  </List>
);

export default ResourceList;
