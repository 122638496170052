import React from 'react';

import {
  TextInput,
  DateInput,
  Create,
  SimpleForm,
  BooleanInput,
  SelectArrayInput,
  required,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const dateParser = (v) => {
  let time = v;
  time += 'T04:00';
  if (v.charAt(0) != '0') {
    return time;
  }
  return v;
};

export const ResourceCreate = (props) => (
  <Create {...props} title={'Nova Dica'}>
    <SimpleForm>
      <BooleanInput source='status' label='Status' />
      <TextInput
        fullWidth
        source='title'
        label='Título'
        validate={[required()]}
      />
      <DateInput
        fullWidth
        source='availableAt'
        label='Disponivel em'
        options={{
          format: 'DD/MM/YYYY, HH:mm:ss',
          ampm: false,
          clearable: true,
        }}
        parse={dateParser}
        validate={[required()]}
      />
      <RichTextInput
        fullWidth
        source='description'
        label='Descrição'
        validate={[required()]}
      />
      <TextInput
        fullWidth
        source='youtubeVideo'
        label='Video (Link de YouTube)'
        validate={[required()]}
      />
      <SelectArrayInput
        fullWidth
        source='userType'
        label='Tipo de usuário'
        choices={[
          { id: 'teacher', name: 'Professor' },
          { id: 'family', name: 'Família' },
        ]}
        validate={[required()]}
      />
    </SimpleForm>
  </Create>
);

export default ResourceCreate;
