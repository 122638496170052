import React, { useEffect, useState } from 'react';
import { AppBar } from 'react-admin';
import MyUserMenu from './MyUserMenu';
import feathersClient from '../feathersClient';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  spacer: {
    flex: 1
  }
});

const MyAppBar = props => {
  const [title, setTitle] = useState('ZOOM EDUCATION');
  const classes = useStyles();
  useEffect(() => {
    feathersClient
      .service('users')
      .get('me')
      .then(async user => {
        let school;
        if (user.entiny_type == 'School') {
          school = user.entiny_id;
        }
        if (user.entiny_type == 'Teacher') {
          school = user.entiny_id;
          let teachers = await feathersClient
            .service('teachers')
            .find({ query: { id: user.entiny_id, $limit: '-1' } });
          school = teachers[0].school_id;
        }
        if (user.entiny_type == 'School' || user.entiny_type == 'Teacher') {
          feathersClient
            .service('schools')
            .find({ query: { id: school, $limit: '-1' } })
            .then(schools => {
              setTitle(`${schools[0].company_name}`);
            });
        }
      });
  }, []);
  console.log('props', props);
  return (
    <>
      <AppBar {...props} userMenu={<MyUserMenu />}>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            height: 70,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div
            className="school-name"
            style={{ display: 'flex', fontFamily: 'Overpass' }}
          >
            {title}
          </div>
          <div style={{ display: 'flex' }} id="react-admin-title"></div>
        </div>
      </AppBar>
    </>
  );
};
export default MyAppBar;
