import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import OpenIcon from '@material-ui/icons/OpenInBrowser';

/**
 * Esse componente recebe uma URL para um arquivo PDF e exibe 1 botão para visualizar o pdf
 * o visualizador do google docs é usado em um iframe para exibir o pdf que vem como anexo para download
 * para usar outros visualizadores de pdf online checar o link: https://gist.github.com/tzmartin/1cf85dc3d975f94cfddc04bc0dd399be
 */

const PdfVisualizer = props => {
  const [open, setOpen] = useState(false);

  const pdfVisualizer = 'http://view.officeapps.live.com/op/view.aspx?src=';

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const styles = {
    modalStyle: {
      width: '80%',
      height: '50%'
    },
    buttonContainer: {},
    buttonOption: {
      width: 200,
      marginRight: 15
    },
    warning: {
      fontSize: 14,
      color: 'red'
    }
  };

  // Usado para saber se o material possui um PDF
  const havePdf = props.pdf != null ? true : false;

  return (
    <div>
      <div>
        {/* <Button
          style={styles.buttonOption}
          variant='contained'
          color='primary'
          onClick={() => window.open(props.pdf)}
          startIcon={<SaveIcon />}
          disabled={!havePdf}
        >
          Baixar PDF
        </Button> */}
        <Button
          style={styles.buttonOption}
          variant='contained'
          color='primary'
          onClick={handleOpen}
          startIcon={<OpenIcon />}
          disabled={!havePdf}
        >
          Visualizar PDF
        </Button>
      </div>
      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={open}
        onClose={handleClose}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <iframe
          width='80%'
          height='95%'
          title='PDF'
          src={
            'https://docs.google.com/gview?url=' + props.pdf + '&embedded=true'
          }
          type='application/pdf'
        >
          <p>
            It appears your Web browser is not configured to display PDF files.
            No worries, just
            <a href='your_file.pdf'>click here to download the PDF file.</a>
          </p>
        </iframe>
      </Modal>
    </div>
  );
};

export default PdfVisualizer;
