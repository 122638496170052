import React from 'react';
import { Filter, ReferenceInput, SelectInput } from 'react-admin';

const ReseourceFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      fullWidth
      label='Atividade'
      source='material'
      reference='materials'
      alwaysOn
      link={false}
    >
      <SelectInput fullWidth optionText='name' />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label='Programa'
      source='program'
      reference='programs'
      alwaysOn
    >
      <SelectInput fullWidth optionText='name' />
    </ReferenceInput>
    {(props.permissions == 'School' ||
      props.permissions == 'Contributor' ||
      props.permissions == 'Franchisee') && (
      <ReferenceInput
        fullWidth
        label='Professor'
        source='teacher'
        reference='teachers'
        alwaysOn
      >
        <SelectInput fullWidth optionText='name' />
      </ReferenceInput>
    )}
    <ReferenceInput
      fullWidth
      label='Turma'
      source='classroom'
      reference='classrooms'
      alwaysOn
    >
      <SelectInput fullWidth optionText='name' />
    </ReferenceInput>
  </Filter>
);

export default ReseourceFilter;
