import React, { useEffect, useState } from 'react';
import {
  ArrayField,
  ChipField,
  Edit,
  email,
  FormDataConsumer,
  FormTab,
  ReferenceInput,
  required,
  SelectInput,
  SingleFieldList,
  TabbedForm,
  TextInput,
} from 'react-admin';
import GeneratePasswordButton from '../../../components/GeneratePasswordButton';
import ProgramCards from '../../../components/ProgramCards';
import feathersClient from '../../../feathersClient';
import emailValidation from '../../../validations/emailValidation';

import CitySelect from './CitySelect';

const Title = ({ record }) => {
  return <span>{record ? `${record.company_name}` : ''}</span>;
};

const SchoolEdit = ({ permissions, ...props }) => {
  const [states, setStates] = useState([]);
  useEffect(() => {
    feathersClient
      .service('states')
      .find({ query: { $limit: '-1' } })
      .then((_states) => {
        setStates(
          _states.map((state) => {
            let cities = state.cities.map((city) => {
              return { id: city._id, name: city.name };
            });
            return { id: state._id, name: state.name, cities: cities };
          })
        );
      });
  }, []);

  return (
    <Edit
      bulkActionButtons={false}
      {...props}
      title={<Title />}
      // filters={<SchoolFilter />}
    >
      <TabbedForm>
        <FormTab label='Dados'>
          <GeneratePasswordButton />
          <ArrayField source='accessCodes' label='Código de acesso'>
            <SingleFieldList linkType={false}>
              <ChipField source='code' />
            </SingleFieldList>
          </ArrayField>
          <ReferenceInput
            fullWidth
            label='Franquia'
            source='franchisee'
            reference='franchisees'
            disabled={!(permissions == 'Contributor')}
          >
            <SelectInput
              fullWidth
              optionText='company'
              disabled={!(permissions == 'Contributor')}
            />
          </ReferenceInput>
          <TextInput
            fullWidth
            source='email'
            label='Email'
            validate={[required(), email(), emailValidation]}
          />
          <TextInput
            fullWidth
            source='company'
            label='Razão Social'
            validate={required()}
          />
          <TextInput
            fullWidth
            source='company_name'
            label='Nome Fantasia'
            validate={required()}
          />
          <TextInput fullWidth source='tel' label='Telefone' />
          <TextInput fullWidth source='cel' label='Celular' />
          <TextInput fullWidth source='cnpj' label='Cnpj' />
          <TextInput fullWidth source='inep' label='Inep' />
          <TextInput fullWidth source='ie' label='Inscrição estadual' />
        </FormTab>
        <FormTab label='Endereço'>
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexWrap: 'wrap',
              width: '100%',
            }}
          >
            <div
              style={{
                minWidth: 400,
                flex: 1,
                marginTop: 20,
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              <div
                style={{
                  color: '#fff',
                  backgroundColor: '#2196f3',
                  padding: 10,
                  fontSize: 20,
                  fontFamily: 'Overpass',
                  flex: 1,
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                ENDEREÇO DE COBRANÇA
              </div>
              <SelectInput
                fullWidth
                label='Estado'
                source='billing_address[state_id]'
                choices={states}
              />
              <FormDataConsumer fullWidth>
                {({ formData, ...rest }) => (
                  <CitySelect
                    label='Cidade'
                    states={states}
                    formData={formData}
                    field='billing_address'
                    {...rest}
                  />
                )}
              </FormDataConsumer>
              <TextInput
                fullWidth
                source='billing_address[neighborhood]'
                label='Bairro'
              />
              <TextInput
                fullWidth
                source='billing_address[street]'
                label='Endereço'
              />
              <TextInput
                fullWidth
                source='billing_address[number]'
                label='Número'
              />
              <TextInput
                fullWidth
                source='billing_address[patio_type]'
                label='Complemento'
              />
              <TextInput
                fullWidth
                source='billing_address[zipcode]'
                label='Cep'
              />
            </div>
            <div
              style={{
                minWidth: 400,
                flex: 1,
                marginTop: 20,
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              <div
                style={{
                  color: '#fff',
                  backgroundColor: '#2196f3',
                  padding: 10,
                  fontSize: 20,
                  fontFamily: 'Overpass',
                  flex: 1,
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                ENDEREÇO DE ENTREGA
              </div>
              <SelectInput
                fullWidth
                label='Estado'
                source='shipping_address[state_id]'
                choices={states}
              />
              <FormDataConsumer fullWidth>
                {({ formData, ...rest }) => (
                  <CitySelect
                    label='Cidade'
                    states={states}
                    formData={formData}
                    field='shipping_address'
                    {...rest}
                  />
                )}
              </FormDataConsumer>
              <TextInput
                fullWidth
                source='shipping_address[neighborhood]'
                label='Bairro'
              />
              <TextInput
                fullWidth
                source='shipping_address[street]'
                label='Endereço'
              />
              <TextInput
                fullWidth
                source='shipping_address[number]'
                label='Número'
              />
              <TextInput
                fullWidth
                source='shipping_address[patio_type]'
                label='Complemento'
              />
              <TextInput
                fullWidth
                source='shipping_address[zipcode]'
                label='Cep'
              />
            </div>
          </div>
        </FormTab>
        <FormTab label='Programas'>
          <ProgramCards />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default SchoolEdit;
