import XLSX from 'xlsx';
import feathers from '../../../feathersClient';

const exporter = resources => {
  let data = [
    [
      'Código de acesso',
      'Franquia',
      'Razão social',
      'Nome fantasia',
      'Email',
      'Cnpj'
    ]
  ];
  Promise.all(
    resources.map(resource => {
      return new Promise(async (resolve, reject) => {
        let item = {
          franchiseeName: '-'
        };
        await feathers
          .service('franchisees')
          .get(resource.franchisee)
          .then(franchisee => {
            item.franchseeName = franchisee.company_name;
          })
          .catch(err => {});
        data.push([
          resource.accessCodes[0].code,
          ...Object.values(item),
          resource.company,
          resource.company_name,
          resource.email,
          resource.cnpj
        ]);
        resolve();
      });
    })
  ).then(() => {
    let worksheet = XLSX.utils.aoa_to_sheet(data);
    let new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'Escolas');
    XLSX.writeFile(new_workbook, `Escolas.xlsx`);
  });
};

export default exporter;
