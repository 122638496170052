import Chip from '@material-ui/core/Chip';
import React from 'react';

const TextArrayField = ({ record, source }) => {
  console.log(record);
  console.log(source);
  const array = record[source];
  console.log(array);
  if (typeof array === 'undefined' || array === null || array.length === 0) {
    return <div />;
  } else {
    return (
      <>
        {array.map((item) => (
          <Chip label={item} key={item} />
        ))}
      </>
    );
  }
};
TextArrayField.defaultProps = { addLabel: true };

export default TextArrayField;
