import React from 'react';
import { Create, email, required, SimpleForm, TextInput } from 'react-admin';
import emailValidation from '../../../validations/emailValidation';

const ResourceCreate = ({ permissions, ...props }) => {
  return (
    <Create {...props} title="Nova Franquia">
      <SimpleForm>
        <TextInput
          fullWidth
          source="email"
          label="Email"
          validate={[required(), email(), emailValidation]}
        />
        <TextInput
          fullWidth
          source="company"
          label="Razão Social"
          validate={required()}
        />
        <TextInput
          fullWidth
          source="company_name"
          label="Nome Fantasia"
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};

export default ResourceCreate;
